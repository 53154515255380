import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import DriverFormOne from "./components/Driver/DriverFormOne";
import SignUp from "./components/signup/SignUp";
import PartnerFormOne from "./components/Partners/PartnerFormOne";
import DriverFormTwo from "./components/Driver/DriverFormTwo";
import PartnerFormTwo from "./components/Partners/PartnerFormTwo";
import JobSeeker from "./components/WelcomePage/JobSeeker";
import WelcomePartner from "./components/WelcomePage/WelcomePartner";
import Login from "./components/login/Login";
import PostJob from "./components/jobs/PostJob";
import JobsList from "./components/jobs/JobsList";
import JobSubmission from "./components/jobs/JobSubmission";
import JobDetails from "./components/jobs/JobDetails";
import EditJob from "./components/jobs/EditJob";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import JobPostingPayment from "./components/jobs/JobPostingPayment";
import DriverDashboard from "./components/Dashboard/DriverDashboard";
import AuthProvider from "./components/Auth/AuthProvider";
import PrivateRoute from "./components/Auth/PrivateRoute";
import WelcomePostJob from "./components/jobs/WelomePostJob";
import PartnerProfile from "./components/Dashboard/PartnerDashbaord.js";
import JobEditForm from "./components/jobs/JobEditForm.js";
import TermsAndCondtions from "./components/TermsAndConditions/TermsAndCondtions.js";
import EmailVerification from "./components/Email/EmailVerification.js";
import PrivacyPolicy from "./components/TermsAndConditions/PrivacyPolicy.js";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/verify-email/:verificationCode"
              element={<EmailVerification />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/driverone" element={<DriverFormOne />} />
            <Route path="/drivertwo" element={<DriverFormTwo />} />
            <Route path="/partnerone" element={<PartnerFormOne />} />
            <Route path="/partnertwo" element={<PartnerFormTwo />} />
            <Route path="/jobs" element={<JobsList />} />
            <Route path="/jobseeker" element={<JobSeeker />} />
            <Route path="/welcomepartner" element={<WelcomePartner />} />
            <Route path="/jobs/:jobId" element={<JobDetails />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondtions />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route path="/reset-password/:uid/:token" element={<ForgotPassword />} />


            {/* Protected Routes */}
            <Route element={<PrivateRoute />}>
              <Route
                path="*"
                element={
                  <Routes>
                    <Route path="/postjob" element={<PostJob />} />
                    <Route
                      path="/welcomepostjob"
                      element={<WelcomePostJob />}
                    />
                    <Route path="/jobsubmission" element={<JobSubmission />} />
                    <Route path="/editjob/:id" element={<EditJob />} />
                    <Route path="/jobs/:id/edit" element={<JobEditForm />} />
                    <Route
                      path="/postjobpayment"
                      element={<JobPostingPayment />}
                    />
                    <Route
                      path="/partnerdashboard"
                      element={<PartnerProfile />}
                    />
                    <Route
                      path="/driverdashboard"
                      element={<DriverDashboard />}
                    />
                  </Routes>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
