import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import NavBarThree from '../navbar/NavBarThree';
import Footer from '../footer/Footer';
import { FaEdit } from 'react-icons/fa';
import api from '../../services/api';
import Spinner from '../spinner/Spinner';
import { useLocation } from 'react-router-dom';

function EditJob() {
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(!!location.state?.successPopup);
    const { id } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [vehicleMakes, setVehicleMakes] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [workAreas, setWorkAreas] = useState([]);


    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                setShowPopup(false);
            }, 6000);

            return () => clearTimeout(timer);
        }
    }, [showPopup]);

    // Fetch job data by ID
    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await api.get(`/api/jobs/${id}/`);
                setJob(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching job:', error.response?.data || error.message);
                setError('Job not found or failed to load.');
                setLoading(false);
            }
        };

        fetchJob();
    }, [id]);

    // Fetch vehicle makes, models, and work areas
    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const [makesResponse, modelsResponse, areasResponse] = await Promise.all([
                    api.get('/api/vehicle-makes/'),
                    api.get('/api/vehicle-models/'),
                    api.get('/api/work-areas/'),
                ]);

                setVehicleMakes(makesResponse);
                setVehicleModels(modelsResponse);
                setWorkAreas(areasResponse);
            } catch (error) {
                console.error('Error fetching metadata:', error.response?.data || error.message);
            }
        };

        fetchMetadata();
    }, []);

  
    const getVehicleMakeName = (makeId) => {
        const make = vehicleMakes.find((item) => item.id === makeId);
        return make ? make.name : 'Unknown Make';
    };

  
    const getVehicleModelName = (modelId) => {
        const model = vehicleModels.find((item) => item.id === modelId);
        return model ? model.name : 'Unknown Model';
    };

   
    const getWorkAreaName = (areaId) => {
        const area = workAreas.find((item) => item.id === areaId);
        return area ? area.name : 'Unknown Area';
    };

   
    const handleUpdateClick = () => {
        navigate(`/jobs/${id}/edit`); 
    };

    if (loading) {
        return <div><Spinner /></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
            <NavBarThree />
            <div className="relative flex-grow flex justify-center items-center">
                <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg w-full max-w-full h-auto overflow-y-auto mx-4 md:mx-8">
                    <div className='bg-gray-100 p-2 rounded-xl w-full'>
                        <h1 className='font-serif font-bold py-2 md:px-4 px-2 text-xl md:text-2xl md:mr-4 xl:text-3xl'>View / Edit Your Posted Job</h1>
                    </div>
                    {showPopup && (
                        <div className="fixed top-26 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
                            {location.state.successPopup}
                        </div>
                    )}
                    <div className='md:flex justify-center items-center xl:p-5 bg-white w-full mt-4 rounded-xl md:pb-0 mb-10 md:mb-16 pb-10'>
                        <div className='px-4 pt-4 pb-8 md:w-[40%]'>
                            <div className="grid grid-cols-1 xl:gap-6 md:gap-4">
                                <div key={job.id} className="bg-gray-300 w-full py-4 px-1 md:px-6 flex flex-col items-start rounded-xl gap-1 relative">
                                    <div className="text-2xl xl:text-4xl font-semibold font-serif mr-2">{`Job ID: ${job.id}`}</div>
                                    <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Location:</span> {getWorkAreaName(job.preferred_work_area)}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Make:</span> {getVehicleMakeName(job.vehicle_make)}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Model:</span> {getVehicleModelName(job.vehicle_model)}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Min Age:</span> {job.min_age}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Max Age:</span> {job.max_age}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs xl:text-lg text-left font-serif mr-2 flex items-center">
                                        <span className='font-bold mr-2'>Work Days:</span>  {job.work_days}
                                        <FaEdit className="ml-2 text-xs cursor-pointer" />
                                    </div>
                                    <div className="text-xs flex-col xl:text-lg text-left font-serif mr-2 flex">
                                        <span className='font-bold mr-2'>Requirements:</span> {job.requirements}
                                        <FaEdit className=" text-xl cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center justify-center md:w-[30%] mb-5'>
                            <div className='p-2 w-[70%] bg-orange-600 rounded-lg relative'>
                                <img src={job.vehicle_photo} alt="Vehicle" className="h-auto mx-auto" />
                                <FaEdit className="absolute bottom-1 right-1 text-xs cursor-pointer" />
                            </div>
                        </div>

                        <div className='flex items-center justify-center md:w-[30%] mt-16 md:mt-0 mb-10'>
                            <button className="bg-orange-700 text-black font-serif xl:text-2xl text-sm py-2 w-[80%] rounded-xl flex items-center justify-center">
                                Awaiting Approval
                                <div className="ml-4 w-4 h-4 bg-red-600 rounded-full"></div>
                            </button>
                        </div>
                    </div>
                    <div className='flex justify-end xl:md:mb-20 mb-5 md:mb-10 mx-16 md:mx-10'>
                        <button className="bg-orange-700 text-white font-semibold font-serif xl:text-2xl text-sm py-1 px-16 rounded-xl" onClick={handleUpdateClick}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditJob;



