import React, { useEffect } from 'react';
// import React from 'react';
import Navbar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import TruncatedText from '../truncatedtext /TruncatedText';
import { useLocation } from 'react-router-dom';
import Contacts from '../Contacts/Contacts';




const Home = () => {
  const data = [
    {
      imgSrc: "/assets/Pay.png",
      heading: "Convenient and User- Friendly Platform",
      text: "Whether on the web or mobile our platform makes it simple for you to create profiles browse jobs and apply for positions that match your skills and preferences."
    },
    {
      imgSrc: "/assets/timevectoricon.png",
      heading: "Efficient Hiring Process",
      text: "Streamline your recruitment by posting job openings and receiving applications directly through our platform, saving time and effort."
    },
    {
      imgSrc: "/assets/drivericon.png",
      heading: "Access to a Large Pool of Qualified Drivers",
      text: "Easily find and interview a wide range of skilled drivers to increase your chances of hiring the best candidates."
    },
    {
      imgSrc: "/assets/carsicon.png",
      heading: "Discover Diverse Job Opportunities",
      text: "Break free from the limitations of referrals and explore a variety of driving jobs available from multiple partners."
    }
  ];

  const location = useLocation(); // Hook is called inside the functional component

  useEffect(() => {
    // Check if the URL contains the hash for the about-us section
    if (location.hash === '#about-us-section') {
      const aboutSection = document.getElementById('about-us-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]); // Add location as a dependenc

  return (
    <div className="flex flex-col w-full p-0 m-0 overflow-hidden">
      {/* navbar */}
      <Navbar />
      {/* First page */}
      <div className="md:h-[85vh] h-screen  flex w-full relative">
        <div className=" hidden md:block absolute z-20 bottom-0 left-0 w-[70%] h-auto">
          <img src="/assets/car12.png" alt="Car" className="w-[100%] xl:w-[95%]" />
        </div>
        <div className=" md:hidden  absolute z-20 bottom-0 left-0 w-[98%] h-auto">
          <img src="/assets/car132.png" alt="Car" className="w-[95%] xl:w-[95%] mt-10" />
        </div>
        <div className="w-[34%] bg-orange-700 flex flex-col items-start justify-start relative">
          <img src="/assets/track 1.png" alt="Track" className="hidden md:block md:h-[52vh] mt-4 transform translate-x-[10%] w-[100%]" />
        </div>
        <div className="w-[66%] flex flex-col items-center md:items-start bg-white mb-10">
          <div className="flex flex-col mx-4 items-center md:items-start xl:mt-16 mt-6 md:ml-20 xl:ml-32 md:w-[90%] relative">
            <h1 className="md:text-3xl md:items-start justify-start md:text-left text-xl font-bold mb-4 font-serif xl:text-5xl md:px-0 px-6 tracking-wider">Connect with Partners and Discover <br /> Your Ideal Driving Opportunity <br />Today</h1>
            <span className='bg-orange-700 h-[1vh] xl:w-24 w-[80%] md:w-12 '></span>
            <p className="md:text-lg text-sm  xl:text-3xl text-gray-500 mt-4 md:px-0 px-6 mb-12  font-serif">Connect with Employers and Find Your Ideal Driving Job Here!</p>
          </div>
          <a href='/jobs' className='z-30'>
            <button className="flex flex-col md:text-xl xl:text-2xl text-xs items-end md:mt-28 mt-1 md:absolute md:bottom-0 md:right-20  bg-black text-white p-3 font-serif font-bold" alt="View Opportunities">View Opportunities</button>
          </a>
        </div>
      </div>




      {/* Second page */}
      <div id="about-us-section" className="min-h-screen flex relative w-full mt-10" style={{ backgroundImage: 'url(/assets/track33.png)', backgroundSize: '85% 100%', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
        <div className="w-full  absolute bottom-0  right-0 z-40">
          <img src="/assets/car22.png" alt="Track" className="absolute bottom-0 right-0 md:w-[50%] " />
        </div>
        <div className="w-[70%] md:w-[50%] flex flex-col md:px-4 items-center bg-opacity-0">
          <div className='flex flex-col items-center mb-3 md:mb-5'>
            <img src="/assets/logolandingpage2.png" alt="Logo" className="md:hidden block h-24 mb-2 px-4" />
            <div className="w-[60%] md:hidden h-[2vh] bg-black-to-white-top"></div>
            <h1 className="md:hidden block text-3xl text-black font-bold  font-serif ">How This Works</h1>
          </div>

          <div className="items-start bg-transparent hidden md:block md:text-lg xl:text-3xl md:mt-4 xl:mt-12 text-sm text-gray-800 mb-2 font-serif md:px-2 ml-20">
            <p className="text-lg mb-6 text-left xl:text-3xl">
              Our platform connects skilled drivers with employers seeking reliable candidates for their transportation needs. Whether you're a cab driver, bus operator, or lorry driver, finding the right job has never been easier.
            </p>
            <div className="flex flex-col mb-4 px-4">
              <p className="text-md text-left xl:text-3xl xl:mb-3">
                <span className="font-bold">1. </span><span className="ml-2 font-bold">Create a Profile:</span> Sign up and create a detailed profile highlighting your experience and skills.
              </p>
              <p className="text-md text-left xl:text-3xl xl:mb-3">
                <span className="font-bold">2. </span><span className="ml-2 font-bold">Browse Jobs:</span> Explore a variety of driving job opportunities posted by employers.
              </p>
              <p className="text-md text-left xl:text-3xl xl:mb-3">
                <span className="font-bold">3. </span><span className="ml-2 font-bold">Apply:</span> Submit your application directly through our platform.
              </p>
              <p className="text-md text-left xl:text-3xl">
                <span className="font-bold">4. </span><span className="ml-2 font-bold">Get Hired:</span> Connect with employers and secure your ideal driving job.
              </p>
            </div>
          </div>
          <TruncatedText />
          <a href="/jobs">
            <button className="md:mt-2 mb-2 xl:w-72 w-48 ">
              <img src="/assets/buttons/viewjobsbutton.png" alt="View Jobs" className='md:w-[90%] xl:w-[100%] h-auto hidden md:block' />
            </button>
          </a>
          <a href="/jobs" className='z-50'>
            <button className="bg-black text-white md:hidden py-1.5 px-4 font-serif font-bold">
              View Jobs
            </button>
          </a>
        </div>
        <div className="w-[30%] md:w-[50%] flex flex-col items-center relative">
          <div className="w-full h-full flex relative z-10">
            <div className="w-[25%] md:block hidden bg-transparent" style={{ opacity: 0.7 }}></div>
            <div className="md:w-[55%] w-[65%] bg-orange-700 flex flex-col items-center" style={{ opacity: 0.8 }}>
              <img src="/assets/landingpageogo.png" alt="Logo" className="h-20 xl:h-32 mb-2 mt-8 px-4 md:block hidden" />
              <h1 className="text-2xl xl:text-5xl text-white font-bold font-arimo md:block hidden">How This Works</h1>
            </div>
            <div className="md:w-[20%] w-[10%] bg-transparent" style={{ opacity: 0.7 }}></div>
          </div>
        </div>
      </div>




      {/* Third page */}
      <div className="flex relative w-full mt-10 min-h-screen md:min-h-screen mb-10">
        <div className="md:w-[50%] w-[30%] flex flex-col items-center relative">
          <div className="w-full h-full flex relative z-10">
            <div className="w-[25%] bg-transparent"></div>
            <div className="md:w-[55%] w-[100%] bg-orange-700 flex flex-col items-center">
              <img src="/assets/landingpageogo.png" alt="Logo" className="hidden md:block h-20 xl:h-32 mb-4 mt-8 px-4" />
              <h1 className="hidden md:block text-2xl text-white xl:text-5xl font-bold font-serif">Our Services</h1>
            </div>
            <div className="w-[20%] bg-transparent"></div>
          </div>
        </div>
        <div className="md:w-[50%] w-[70%] flex flex-col items-start justify-start bg-opacity-0 md:px-8 py-4 md:py-20 relative pr-3">
          <div className='flex flex-col items-center mr-7 w-[80%] mb-2'>
            <img src="/assets/logolandingpage2.png" alt="Logo" className="md:hidden block h-16 px-4 mb-2" />
            <div className="w-[60%] md:hidden h-[2vh] bg-black-to-white-top"></div>
            <h1 className="md:hidden block text-3xl text-black font-bold font-serif ">Our Services</h1>
          </div>
          <h2 className="text-xl xl:text-4xl xl:mt-20 font-bold text-start text-orange-700 font-serif">Job Applications for Drivers</h2>
          <p className="md:text-lg xl:text-3xl xl:mt-3 text-sm text-gray-800 mb-1 md:mb-6 font-serif text-start">Drivers can apply for various car rental jobs posted by partners seeking skilled candidates.</p>
          <h2 className="text-xl xl:text-4xl font-bold text-start text-orange-700 font-serif">Driver-Partner Connection</h2>
          <p className="md:text-lg xl:text-3xl  text-sm xl:mt-3  text-gray-800 mb-1 md:mb-6 font-serif text-start ">We facilitate connections between drivers and
            partners, helping partners find reliable drivers and
            drivers discover job opportunities.
          </p>
          <h2 className="text-xl xl:text-4xl  font-bold text-start text-orange-700 font-serif">Job Posting for Drivers</h2>
          <p className="md:text-lg xl:text-3xl hidden md:block text-sm xl:mt-3  text-gray-800 mb-6 font-serif text-start"> Partners can easily post job openings, specifying
            their requirements and job details to attract
            suitable drivers.
          </p>
          <p className="md:text-lg xl:text-3xl md:hidden  text-sm xl:mt-3  text-gray-800 md:mb-6 font-serif text-start"> Partners can easily post job openings, specifying
            their requirements and job details to attract
            suitable drivers.
          </p>
          <div className='md:flex justify-end  xl:mt-6 mt-4 md:pl-20 items-end w-full z-50'>
            <a href='/jobs'>
              <button className="md:mt-6 w-36 md:w-60 xl:w-60 md:ml-0 ml-32 md:pl-8">
                <img src="/assets/buttons/viewcarsbutton.png" alt="View Cars" className='w-[70%] xl:w-[100%] h-auto' />
              </button>
            </a>
            {/* <button className="md:mt-6 w-36 md:w-48 xl:w-64 md:ml-0 ml-36 md:pl-10">
              <img src="/assets/buttons/viewdriversbutton.png" alt="View Drivers" className='w-[80%] xl:w-[100%] h-auto' />
            </button> */}
          </div>

        </div>
        <div className="w-full  absolute bottom-0 mt-20  left-0 z-40">
          <img src="/assets/car3.png" alt="Track" className=" md:w-[50%] w-[95%]" />
        </div>
      </div>


      {/* Fourth page */}
      <div className="md:min-h-screen py-4 bg-cover" style={{ backgroundImage: 'url(/assets/backgroundorange.svg)' }}>
        <div className="flex flex-col items-center text-center p-4">
          <img src="/assets/landingpageogo.png" alt="Logo" className="md:hidden block h-20 px-4 mb-4" />
          <h2 className="text-3xl xl:text-6xl font-bold md:mt-16  text-white font-serif">WHY PICK US</h2>
        </div>

        <div className="flex items-center xl:mt-10 justify-center">
          <div className="bg-gray-200 bg-opacity-65 xl:p-12 p-4 mx-4 md:mx-12 w-full md:w-[80%] flex flex-wrap rounded-xl h-[90%] md:h-auto">
            {data.map((item, index) => (
              <div key={index} className="w-[50%] md:flex flex-row items-start p-1 md:p-2 md:mt-4 md:mb-4">
                <img src={item.imgSrc} alt="Logo" className="md:w-[32%] w-[80%] mt-2" />
                <div className="ml-0 flex flex-col items-start md:mt-4">
                  <h2 className="md:text-lg xl:text-4xl text-orange-700 xl:mt-6 text-left font-bold font-serif">{item.heading}</h2>
                  <p className="text-gray-800 mt-2 xl:text-2xl text-left md:text-lg w-[95%] md:w-[80%] text-xs font-serif items-start">
                    {item.text.split(',').map((line, idx) => (
                      <span key={idx}>
                        {line}
                        {idx < item.text.split(',').length - 1 && <br />}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    <Contacts/>


      {/* 6th page - footer */}
      < Footer />
    </div>
  );
};

export default Home;







