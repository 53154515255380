import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarThree from '../navbar/NavBarThree';
import Footer from '../footer/Footer';

function WelcomePostJob() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/postjob');
    };
    const handleClickOne = () => {
        navigate('/partnerdashboard');
    };

    return (
        <div className="flex flex-col h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
            {/* Navbar */}
            <NavBarThree />

            <div className="relative flex-grow md:mt-8 mt-4 flex justify-center items-center md:h-[70%]">
                <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg md:p-4 p-2 w-full max-w-full h-full overflow-y-auto mx-8">

                    <div className="font-serif text-black text-base md:text-lg mt-4 mb-4">
                        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold font-serif text-black mb-2"> Your Job was successfuly submitted </h2>
                    </div>
                    <div className="text-center p-4">
                        <img src="/assets/success.png" alt="Welcome" className="mx-auto mb-1 w-[30%] md:w-[10%] h-auto" />
                    </div>
                    <div>
                        <h2 className='text- md:text-2xl xl:text-3xl font-serif text-gray mb-2"'>It will go live on the website after a brief review process.</h2>
                        <div className='mt-4'>
                            <p className='font-serif text-gray mb-2"'>Incase of further inquiries a represenative will contact you via phone or email.</p>
                        </div>


                    </div>

                    {/* Buttons */}
                    <div className="mt-8 mb-8 md:flex justify-center md:justify-between">
                        <button type="button" className="md:ml-32 bg-orange-700 text-white mx-2 py-1.5 px-3 md:px-28 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif" onClick={handleClick}>
                            Post Another Jobs
                        </button>

                        <button type="button" className="md:mr-32 bg-gray-500 text-white mx-2 py-1.5 px-3 md:px-28 rounded-lg hover:bg-gray-800 text-xs xl:text-lg font-serif" onClick={handleClickOne}>
                            View All Jobs
                        </button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WelcomePostJob;




