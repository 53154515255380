import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const goToSectionWithoutScroll = (e) => {
    e.preventDefault();
    
    const aboutSection = document.getElementById('about-us-section');
    
    if (window.innerWidth < 768) {
      // On mobile, close the menu and navigate to the section
      setIsOpen(false);
      window.location.href = '/#about-us-section';
    } else {
      // On larger screens, smooth scroll to the section
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-full">
      <header className="w-full flex justify-between items-center">
        <div className="flex items-center justify-center w-[34%] bg-orange-700 xl:mt-5">
          <a href="/">
            <img src="/assets/landingpagemainlogo.png" alt="Logo" className="h-20 md:h-32 xl:h-48 mt-2 px-4" />
          </a>
        </div>
        <div className="md:flex hidden bg-transparent justify-end w-[66%] mt-10">
          <nav className="space-x-6">
            <a href="/" className="text-black px-4 ml-10 xl:text-2xl font-serif">Home</a>
            <a href="/jobs" className="text-gray-500 py-4 xl:text-2xl font-serif">Job Opportunities</a>
            <a href="/#about-us-section" onClick={goToSectionWithoutScroll} className="text-gray-500 py-4 xl:text-2xl font-serif">About Us</a>
          </nav>
          <div className="ml-auto space-x-8 px-4 xl:px-6">
            <a href="/login" className="text-black font-serif xl:text-2xl py-4 font-bold">Log In</a>
            <a href="/signup" className="text-black font-serif py-4 xl:text-2xl font-bold">Sign Up</a>
            <a href='/postjob' className="bg-black text-white px-4 py-3 xl:px-6 xl:py-4 font-serif xl:text-2xl font-bold mr-2">Post Job</a>
          </div>
        </div>
        <div className="md:hidden flex items-center px-6">
          <button onClick={toggleMenu} className="text-3xl">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </header>
      {isOpen && (
        <div className="md:hidden fixed inset-0 bg-orange-700 z-50 flex flex-col items-center justify-center menu-open">
          <button onClick={toggleMenu} className="absolute top-6  right-6 text-3xl text-black">
            <FaTimes />
          </button>
          <nav className="flex flex-col space-y-6 text-2xl">
            <a href="/" className="text-white text-lg font-serif font-semibold" onClick={toggleMenu}>Home</a>
            <a href="/jobs" className="text-gray-200 text-lg font-serif" onClick={toggleMenu}>Job Opportunities</a>
            <a href="/#about-us-section" className="text-gray-200 text-lg font-serif" onClick={goToSectionWithoutScroll}>About Us</a>
            <a href="/login" className="text-black  text-lg font-bold font-serif" onClick={toggleMenu}>Log In</a>
            <a href="/signup" className="text-black font-bold text-lg font-serif" onClick={toggleMenu}>Sign Up</a>
            <a href="/postjob" className="bg-black text-white px-4 py-3  text-lg font-bold font-serif" onClick={toggleMenu}>Post Job</a>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
