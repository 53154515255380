
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarTwo from '../navbar/NavBarTwo';
import Footer from '../footer/Footer';

function DriverFormOne() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    id_number: '',
    date_of_birth: '',
    mobile_number: '',
    password: '',
    confirm_password: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input being changed is the mobile number
    if (name === 'mobile_number') {
      // Check if the mobile number starts with +2547 and has 12 characters
      const kenyanMobilePattern = /^\+2547\d{8}$/;
      if (value && !kenyanMobilePattern.test(value)) {
        setMobileError('Please enter a valid Kenyan mobile number starting with +2547XXXXXXXX.');
      } else {
        setMobileError('');
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const [mobileError, setMobileError] = useState(''); // State to handle mobile number error

  // Function to check if the user is 18 years or older
  const isValidAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Check if the user is exactly 18 but hasn't had their birthday this year yet
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  const validateForm = () => {
    if (!formData.first_name || !formData.last_name || !formData.email || !formData.id_number || !formData.date_of_birth || !formData.gender || !formData.mobile_number || !formData.password || !formData.confirm_password) {
      return 'Please fill in all required fields.';
    }
    if (formData.password !== formData.confirm_password) {
      return 'Passwords do not match.';
    }
    if (isValidAge(formData.date_of_birth) < 18) {
      return 'You must be at least 18 years old to register.';
    }
    if (mobileError) {
      return mobileError;
    }
    return null;
  };

  const handleNextClick = () => {
    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    // Clear any previous error and navigate to the next step
    setError('');
    navigate('/drivertwo', { state: { formData } });
  };

  return (
    <div className="flex flex-col min-h-screen bg-cover pt-2" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
      <NavBarTwo />

      <div className="flex-grow flex flex-col items-center">
        <div className="text-center xl:mt-8 mt-4 xl:mb-8 mb-4">
          <h2 className="md:text-3xl text-xl xl:text-5xl font-bold text-white font-serif px-4">
            Create Your Job Seeker Account
          </h2>
          <p className="text-white xl:text-2xl xl:mt-2 font-serif">Your next opportunity is one step away</p>
        </div>
        <div className="flex-grow w-full flex justify-center items-start xl:px-12 px-4 md:px-8">
          <div className="md:flex bg-white bg-opacity-50 rounded-xl shadow-lg w-full md:mx-12">
            <div className="bg-gray-200 p-5 md:w-1/3 flex flex-col justify-center xl:py-10 md:rounded-xl shadow-lg">
              <div className="text-center mt-2">
                <h2 className="md:text-2xl text-xl xl:text-4xl font-bold font-serif text-black">Step One</h2>
                <h2 className="md:text-2xl xl:text-4xl font-bold font-serif text-black">Personal Information</h2>
                <p className="text-black mt-1 text-center font-serif xl:text-2xl text-sm md:text-lg">
                  This is information pertaining to <br /> you as an individual
                </p>
              </div>
            </div>

            <div className="bg-white bg-opacity-50 md:p-5 p-2 md:w-2/3 flex flex-col xl:py-10 md:rounded-r-xl">
              <form className="space-y-3">
                {error && <p className="text-red-500 font-serif text-sm">{error}</p>}

                {/* First Name and Last Name */}
                <div className="grid grid-cols-2 md:gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="firstName" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">First Name<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="firstName" name="first_name" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.first_name} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col ml-2">
                    <label htmlFor="lastName" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Last Name<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="lastName" name="last_name" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.last_name} onChange={handleChange} required />
                  </div>
                </div>

                {/* Email and ID Number */}
                <div className="grid grid-cols-2 md:gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="email" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">Email<span className='px-1 text-red-700'>*</span></label>
                    <input type="email" id="email" name="email" className="p-1.5 text-xs border xl:text-lg border-gray-300 rounded-lg" value={formData.email} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col ml-2">
                    <label htmlFor="idNumber" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">ID Number<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="idNumber" name="id_number" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.id_number} onChange={handleChange} required />
                  </div>
                </div>

                {/* Date of Birth */}
                <div className="flex flex-col">
                  <label htmlFor="date" className="mb-1 text-black uppercase xl:text-lg text-xs font-serif">Date of Birth<span className='px-1 text-red-700'>*</span></label>
                  <input type="date" id="date" name="date_of_birth" className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.date_of_birth} onChange={handleChange} required />
                </div>

                {/* Other fields (Gender, Mobile, Password) */}
                <div className="grid grid-cols-2 md:gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="gender" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">
                      Gender<span className='px-1 text-red-700'>*</span>
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                  {/* Mobile Number Input */}
                  <div className="flex flex-col ml-2">
                    <label htmlFor="mobile" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">
                      Mobile Number<span className="px-1 text-red-700">*</span>
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile_number"
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg"
                      placeholder="+2547XXXXXXXX"
                      value={formData.mobile_number}
                      onChange={handleChange}
                      required
                    />
                    {mobileError && <p className="text-red-500 text-xs">{mobileError}</p>}
                  </div>

                </div>

                {/* Password and Confirm Password */}
                <div className="grid grid-cols-2 md:gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="password" className="mb-1 text-black uppercase xl:text-lg text-left text-xs font-serif">Password<span className='px-1 text-red-700'>*</span></label>
                    <input type="password" id="password" name="password" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.password} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col ml-2">
                    <label htmlFor="confirmPassword" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">Confirm Password<span className='px-1 text-red-700'>*</span></label>
                    <input type="password" id="confirmPassword" name="confirm_password" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.confirm_password} onChange={handleChange} required />
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button type="button" className="bg-orange-700 text-white py-1.5 xl:mt-4 px-7 rounded-lg hover:bg-orange-800 text-xs  xl:text-xl font-serif" onClick={handleNextClick}>
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DriverFormOne;

