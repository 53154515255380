import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import api from '../../services/api';
import Spinner from '../spinner/Spinner';
import { useAuth } from '../Auth/AuthProvider';

const NavbarThree = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logOut } = useAuth();


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await api.get('/api/users/me/');
        setUserData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = () => {
    setIsLoggingOut(true);
    console.log('Logging out...');
    setTimeout(() => {
      logOut();
      setIsLoggingOut(false);
    }, 3000);
  };

  // const goToSectionWithoutScroll = (e) => {
  //   e.preventDefault();

  //   if (window.innerWidth < 768) {
  //     setIsOpen(false);
  //     window.location.href = '/#about-us-section';
  //   } else {
  //     window.location.href = '/#about-us-section';
  //   }
  // };



  if (loading) {
    return <div><Spinner /></div>;
  }

  if (!userData) {
    return <div>Error loading data.</div>;
  }
  const dashboardLink = userData.role === 'driver' ? '/driverdashboard' : (userData.role === 'partner' ? '/partnerdashboard' : '/dashboard');

  return (
    <div className="w-full">
      <header className="w-full flex justify-between items-center">
        <div className="flex items-center w-[34%]  bg-transparent xl:mt-5">
          <a href="/">
            <img
              src="/assets/otherpagesmainlogo.png"
              alt="Logo"
              className="md:h-24 h-20 xl:h-32 md:px-4 px-1 ml-6 md:ml-12"
            />
          </a>
        </div>
        <div className="md:flex hidden justify-end w-[66%] mt-7">
          <nav className="space-x-6">
            <a href="/" className="text-white px-4  xl:text-2xl font-serif font-semibold">Home</a>
            <a href="/jobs" className="text-gray-200 py-4 xl:text-2xl font-serif">Job Opportunities</a>
            {/* <a href="/#about-us-section" className="text-gray-200 py-4 xl:text-2xl font-serif" onClick={goToSectionWithoutScroll}>
              About Us
            </a> */}
            <a href={dashboardLink} className="text-gray-200 py-4 xl:text-2xl font-serif">My Dashboard</a>
          </nav>
          <div className="ml-auto space-x-8 px-4 xl:px-6 flex items-center">
            <div className="rounded-full flex items-center bg-transparent px-4 py-2" onClick={toggleDropdown}>
              <img
                src="/assets/profile.jpeg"
                alt="User"
                className="h-8 w-8 rounded-full mr-2"
              />
              <span className="text-white text-xs font-serif xl:text-lg">
                {userData.first_name} {userData.last_name}
              </span>
            </div>
            {dropdownOpen && (
              <div className="relative flex flex-col items-center">
                <div className="absolute right-20 mt-5 xl:w-40 w-32 bg-white shadow-lg z-10">
                  <div className="absolute -top-3 right-3 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white z-20"></div>
                  <button
                    className="block w-full xl:text-xl text-center px-4 py-2 text-sm text-gray-700 font-serif hover:bg-gray-100"
                    onClick={handleLogout}
                    disabled={isLoggingOut}
                  >
                    {isLoggingOut ? 'Logging out...' : 'Logout'}
                  </button>
                </div>
              </div>

            )}
            {userData.role === 'partner' && (
              <a
                href="/postjob"
                className="bg-black text-white px-4 py-3 xl:px-6 xl:py-4 font-serif xl:text-2xl font-semibold mr-2"
              >
                Post Job
              </a>
            )}
          </div>
        </div>
        <div className="md:hidden flex items-center px-6">
          <button onClick={toggleMenu} className="text-3xl text-white">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </header>

      {isOpen && (
        <div className="md:hidden fixed inset-0 bg-orange-700 z-50 flex flex-col items-center justify-center">
          <button onClick={toggleMenu} className="absolute top-6 right-6 text-3xl text-black">
            <FaTimes />
          </button>
          <nav className="flex flex-col space-y-4 text-2xl">
            <a href="/" className="text-white text-lg font-serif font-semibold" onClick={toggleMenu}>Home</a>
            <a href="/jobs" className="text-gray-200 text-lg font-serif" onClick={toggleMenu}>Job Opportunities</a>
            {/* <a href="/#about-us-section" className="text-gray-200 text-lg font-serif" onClick={goToSectionWithoutScroll}>
              About Us
            </a> */}
            <a href={dashboardLink} className="text-gray-200 py-4 text-lg xl:text-2xl font-serif">My Dashboard</a>
            <div className="flex items-center space-x-4">
              <div className="rounded-full flex items-center bg-transparent px-4 py-2 mb-2" onClick={toggleDropdown}>
                <img src="/assets/profile.jpeg" alt="User" className="h-8 w-8 rounded-full mr-2" />
                <span className="text-white text-xs font-serif">{userData.first_name} {userData.last_name}</span>
              </div>
              {dropdownOpen && (
                <div className="relative flex flex-col items-center">
                  <div className="absolute right-10 mt-5 w-24 bg-white shadow-lg z-10">
                    <div className="absolute -top-3 right-3 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white z-20"></div>
                    <button
                      className="block w-full text-center px-4 py-2 text-sm text-gray-700 font-serif hover:bg-gray-100"
                      onClick={handleLogout}
                      disabled={isLoggingOut}
                    >
                      {isLoggingOut ? 'Logging out...' : 'Logout'}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {userData.role === 'partner' && (
              <a
                href="/postjob"
                className="bg-black text-white text-lg px-4 py-3  font-bold font-serif"
                onClick={toggleMenu}
              >
                Post Job
              </a>
            )}
          </nav>
        </div>
      )}
    </div>
  );
};

export default NavbarThree;



