import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../footer/Footer';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function ForgotPassword() {
    const navigate = useNavigate();
    const { uid, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const data = {
            uid: uid,
            token: token,
            password: password,
            confirm_password: confirmPassword,
        };

        try {
            // const response = await axios.post("http://127.0.0.1:8000/api/password-reset-confirm/", data);
            const response = await axios.post("https://derebackend-1.onrender.com/api/password-reset-confirm/", data);
            setSuccessMessage(response.data.detail);
            setTimeout(() => {
                setSuccessMessage('');
                navigate('/login');
            }, 5000);
        } catch (error) {
            if (error.response && error.response.data.non_field_errors) {
                setErrorMessage(error.response.data.non_field_errors.join(" ") || "Error resetting password!");
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <div className='flex flex-col bg-cover' style={{ backgroundImage: 'url(/assets/background2.0.png)' }}>
            <div className="flex flex-grow w-full h-screen relative">
                <div className="w-full absolute md:hidden block bottom-0 left-0 z-20">
                    <img src="/assets/car55.png" alt="Car" className="w-[100%]" />
                </div>
                <div className="w-[45%] md:w-[55%] flex flex-col items-center relative">
                    <div className="w-full h-full flex relative z-10">
                        <div className="w-[5%] hidden md:block bg-transparent"></div>
                        <div className="md:w-[55%] w-[100%] bg-orange-700 flex flex-col items-center">
                            <img src="/assets/logo2.png" alt="Logo" className="h-20 xl:h-32 mb-4 mt-8 px-4" />
                            <h1 className="text-2xl text-white xl:text-5xl px-1 font-bold font-serif">Forgot<br /> Password</h1>
                        </div>
                        <div className="w-[40%] hidden md:block bg-transparent"></div>
                    </div>
                    <div className="w-full absolute hidden md:block bottom-0 left-0 pt-20 z-20">
                        <img src="/assets/car55.png" alt="Car" className="w-[100%]" />
                    </div>
                </div>
                {/* Forgot password form */}
                <div className="md:w-[45%] w-[55%] flex flex-col items-start justify-start bg-opacity-0 md:px-8 md:mt-2 mt-8 md:py-10 relative">
                    <div className='py-4 md:w-[60%] flex justify-end'>
                        <img src="/assets/login2.png" alt="Track" className="w-[30%]" />
                    </div>
                    <div className="bg-white bg-opacity-50 p-2 md:p-5 w-[96%] mx-1 md:w-[70%] flex flex-col md:rounded-xl">
                        <h2 className='font-serif font-bold text-xl md:text-2xl xl:text-3xl my-2 md:my-4'>Forgot Password</h2>
                        <form className="space-y-3 xl:py-6 w-[100%] xl:px-12 md:px-6" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 xl:gap-5 gap-2 md:gap-3">
                                {/* NEW PASSWORD */}
                                <div className="flex flex-col items-start relative">
                                    <label
                                        htmlFor="password"
                                        className="mb-1 text-black uppercase xl:text-lg text-xs font-semibold font-serif px-1"
                                    >
                                        NEW PASSWORD
                                    </label>
                                    <input
                                        type={showPassword ? "text" : "password"} 
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="p-2 w-[100%] xl:text-lg text-xs border border-gray-300 rounded-lg"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <AiFillEyeInvisible size={20} className="text-gray-500" /> : <AiFillEye size={20} className="text-gray-500 mt-10" />}
                                    </button>
                                </div>

                                {/* CONFIRM PASSWORD */}
                                <div className="flex flex-col items-start relative">
                                    <label
                                        htmlFor="confirmPassword"
                                        className="mb-1 text-black uppercase xl:text-lg text-xs font-semibold font-serif px-1"
                                    >
                                        CONFIRM PASSWORD
                                    </label>
                                    <input
                                        type={showConfirmPassword ? "text" : "password"} 
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="p-2 w-[100%] xl:text-lg text-xs border border-gray-300 rounded-lg"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute right-2 top-10 transform -translate-y-1/2"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <AiFillEyeInvisible size={20} className="text-gray-500" /> : <AiFillEye size={20} className="text-gray-500" />}
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-center md:py-4">
                                <button
                                    type="submit"
                                    className={`bg-orange-700 text-white py-1.5 px-7 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Reseting password..." : "Reset Password"}
                                </button>
                            </div>

                        </form>

                        {/* Success or Error Message */}
                        {successMessage && (
                            <div className="fixed top-24 bg-green-700 md:left-2/5 mr-2 transform md:-translate-x-1/2 text-xs  md:ext-sm xl:text-xl font-serif px-2 md:px-4 py-2 rounded-lg shadow-lg">
                                {successMessage}
                            </div>
                        )}
                        {errorMessage && (
                            <div className="fixed top-24 bg-red-700 mr-2 md:left-2/5 md:transform md:-translate-x-1/2 text-xs md:text-sm xl:text-xl font-serif md:px-4 px-2 py-2 rounded-lg shadow-lg">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
