import React from "react";

function Contacts() {
  return (
    <div>
      {/* 5th page*/}
      <div className="md:min-h-screen md:mb-2 xl:mb-10">
        <div className="text-center">
          <h2 className="text-3xl xl:text-5xl font-bold xl:mt-20  mt-16 text-orange-700 font-serif">
            MAKE AN ENQUIRY
          </h2>
          <p className="font-serif xl:text-2xl xl:mt-6  mt-4 px-2">
            For any driver/partner related enquiries or requests please fill out
            the following form and we'll get back to you as soon as possible.
            <br /> Alternatively contact us directly via telephone or mobile.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full relative mt-8">
          <div className="absolute top-0 w-full h-[100vh] bg-cover flex">
            <div
              className="w-1/2 h-full hidden md:block  bg-no-repeat"
              style={{
                backgroundImage: "url('/assets/car4right.png')",
                backgroundSize: "contain",
              }}
            ></div>
            <div
              className="w-1/2 h-full hidden md:block  bg-no-repeat"
              style={{
                backgroundImage: "url('/assets/car4left.png')",
                backgroundSize: "contain",
              }}
            ></div>
          </div>

          <div className="relative flex flex-col items-center justify-center w-full px-2">
            <div className="bg-red-200 bg-opacity-80 xl:py-16 py-8 md:px-20 px-4 mx-4 md:mx-12 w-full md:w-[70%] rounded-xl">
              <form className="grid grid-cols-2  md:grid-cols-3 md:gap-4 gap-2">
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 xl:text-lg text-sm mb-2 font-serif"
                    htmlFor="firstName"
                  >
                    FIRST NAME:*
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700 border font-serif rounded-lg xl:py-5 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="firstName"
                    type="text"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 xl:text-lg text-sm mb-2 font-serif"
                    htmlFor="lastName"
                  >
                    LAST NAME:*
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700 font-serif border rounded-lg py-3 xl:py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="lastName"
                    type="text"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 xl:text-lg text-sm mb-2 font-serif"
                    htmlFor="phone"
                  >
                    PHONE:*
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700 border font-serif rounded-lg py-3 xl:py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="phone"
                    type="text"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 text-sm xl:text-lg font-serif mb-2"
                    htmlFor="vehicleMake"
                  >
                    VEHICLE MAKE:
                  </label>
                  <select
                    className=" block w-full bg-white font-serif text-gray-700 text-xs xl:text-sm border rounded-lg xl:py-6 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="vehicleMake"
                  >
                    <option>Select</option>
                    <option value="car" className="text-sm">
                      Saloon
                    </option>
                    <option value="car" className="text-sm">
                      Mercedez
                    </option>
                    <option value="car" className="text-sm">
                      Mazda
                    </option>
                  </select>
                </div>
                {/* <div className="flex flex-col items-start">
                  <label className="block text-gray-700 text-sm xl:text-lg mb-2 font-serif" htmlFor="duration">DURATION:*</label>
                  <input className="appearance-none block w-full bg-white text-gray-700 text-xs font-serif border rounded-lg xl:py-6 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="duration" type="text" placeholder="1 day, 1 week, 1 month, 1 year..." />
                </div> */}
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 text-sm xl:text-lg font-serif mb-2"
                    htmlFor="vehicleMake"
                  >
                    VEHICLE MODEL:
                  </label>
                  <select
                    className="block w-full bg-white font-serif text-gray-700 text-xs xl:text-sm border rounded-lg xl:py-6 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="vehicleMake"
                  >
                    <option>Select</option>
                    <option value="car" className="text-sm">
                      Corrola
                    </option>
                    <option value="car" className="text-sm">
                      Demio
                    </option>
                    <option value="car" className="text-sm">
                      Atenza
                    </option>
                  </select>
                </div>
                <div className="flex flex-col items-start">
                  <label
                    className="block text-gray-700 text-sm xl:text-lg mb-2 font-serif"
                    htmlFor="service"
                  >
                    SERVICE:
                  </label>
                  <select
                    className=" block w-full text-xs bg-white text-gray-700 font-serif border rounded-lg py-3 xl:py-6 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="service"
                  >
                    <option>Select</option>leasing
                    <option>Leasing</option>
                    <option>Job Application</option>
                    <option>Posting Job</option>
                    <option>Others</option>
                  </select>
                </div>
                <div className="flex flex-col items-start col-span-full">
                  <label
                    className="block text-gray-700 text-sm xl:text-lg mb-2 font-serif"
                    htmlFor="additionalInfo"
                  >
                    ADDITIONAL INFORMATION:
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-white text-gray-700 border rounded-lg py-3 xl:py-12 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-40"
                    id="additionalInfo"
                  ></textarea>
                </div>
                <div className="flex justify-end col-span-full">
                  <button
                    className="bg-orange-700 text-white xl:text-3xl py-3 px-8 rounded-3xl font-serif hover:bg-orange-800 focus:outline-none focus:bg-orange-600"
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
