import React from "react";
import TermsNavbar from "../navbar/TermsNavBar";
import Footer from "../footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <TermsNavbar />
      <div className="flex flex-col items-center p-12">
        <div className="max-w-3xl text-center">
          <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p className="text-gray-700">
              This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our platform.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
            <p className="text-gray-700 mb-2">
              We collect personal information from two categories of users: drivers and partners.
            </p>
            <div className="mb-4">
              <h3 className="text-lg font-medium">For Drivers:</h3>
              <ul className="list-disc pl-5 text-gray-700 text-left inline-block">
                <li className="whitespace-pre-line">Name</li>
                <li className="whitespace-pre-line">Email</li>
                <li className="whitespace-pre-line">ID</li>
                <li className="whitespace-pre-line">Date of birth</li>
                <li className="whitespace-pre-line">Phone number</li>
                <li className="whitespace-pre-line">Gender</li>
                <li className="whitespace-pre-line">Type of vehicle licensed to drive</li>
                <li className="whitespace-pre-line">Work area preference</li>
                <li className="whitespace-pre-line">ID photo</li>
                <li className="whitespace-pre-line">License photo</li>
                <li className="whitespace-pre-line">PSV photo</li>
                <li className="whitespace-pre-line">Certificate of good conduct photo</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium">For Partners:</h3>
              <ul className="list-disc pl-5 text-gray-700 text-left inline-block">
                <li className="whitespace-pre-line">Name</li>
                <li className="whitespace-pre-line">Email</li>
                <li className="whitespace-pre-line">ID</li>
                <li className="whitespace-pre-line">Date of birth</li>
                <li className="whitespace-pre-line">Phone number</li>
                <li className="whitespace-pre-line">Company number (optional)</li>
                <li className="whitespace-pre-line">Company name (optional)</li>
                <li className="whitespace-pre-line">Car model for the car being leased</li>
                <li className="whitespace-pre-line">Preferred leasing work area</li>
                <li className="whitespace-pre-line">ID photo</li>
                <li className="whitespace-pre-line">Car photo</li>
                <li className="whitespace-pre-line">Company Registration CR12</li>
              </ul>
            </div>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
            <p className="text-gray-700 mb-2">
              We may use your personal information for the following purposes:
            </p>
            <ul className="list-disc pl-5 text-gray-700 text-left inline-block">
              <li className="whitespace-pre-line">To create and manage your account on our platform.</li>
              <li className="whitespace-pre-line">To facilitate job applications and postings.</li>
              <li className="whitespace-pre-line">To communicate with you regarding your account or job opportunities.</li>
              <li className="whitespace-pre-line">To improve our services and user experience.</li>
              <li className="whitespace-pre-line">To comply with legal obligations.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">4. Disclosure of Your Information</h2>
            <p className="text-gray-700">
              We do not sell or rent your personal information to third parties. We may share your information in the following situations:
            </p>
            <ul className="list-disc pl-5 text-gray-700 text-left inline-block">
              <li className="whitespace-pre-line">With partners for the purpose of job placement.</li>
              <li className="whitespace-pre-line">As required by law or to respond to legal requests.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">5. Data Retention</h2>
            <p className="text-gray-700">
              We will retain your personal information for as long as necessary to fulfill the purposes outlined in this policy or as required by law. For drivers, application documents will be stored for a maximum of two weeks, after which they must be re-uploaded for further applications.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">6. Security of Your Information</h2>
            <p className="text-gray-700">
              We take the security of your personal information seriously and implement reasonable security measures to protect it from unauthorized access, disclosure, or misuse.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">7. Your Rights</h2>
            <p className="text-gray-700">
              You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us using the contact information provided below.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
            <p className="text-gray-700">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:{" "}
              <a
                href="mailto:support@kadereconect.com"
                className="text-orange-500 underline"
              >
                support@kadereconect.com
              </a>.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
