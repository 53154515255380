
import React, { useState } from 'react';

const TruncatedText = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const mobileText = [
    "Our platform connects skilled drivers with employers seeking reliable candidates for their transportation needs. Whether you're a cab driver, bus operator, or lorry driver, finding the right job has never been easier.",
    <>
      <strong>1. Create a Profile</strong> 
    </>,
    <>
      <strong>2. Browse Jobs</strong> 
    </>,
    <>
      <strong>3. Apply</strong> 
    </>,
    <>
      <strong>4. Get Hired</strong> 
    </>
  ];

  const displayedLines = isExpanded ? mobileText : mobileText.slice(0, 5);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='md:hidden flex flex-col z-50 px-6'>
      {displayedLines.map((line, index) => (
        <p key={index} className='text-sm text-gray-800 font-serif text-left'>
          {line}
        </p>
      ))}
      {mobileText.length > 5 && (
        <button 
          onClick={toggleExpansion} 
          className="text-red-500">
          {isExpanded ? 'show less' : 'read more'}
        </button>
      )}
    </div>
  );
};

export default TruncatedText;


