import axios from 'axios';

// const BASE_URL = 'http://127.0.0.1:8000/';
const BASE_URL = 'https://derebackend-1.onrender.com/';


const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Create an Axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor to attach the token
apiClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Response Interceptor to handle token refresh
apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      try {
        const refreshToken = getRefreshToken();
        const { data } = await axios.post(`${BASE_URL}/api/token/refresh/`, { refresh: refreshToken });

        localStorage.setItem('token', data.access);
        localStorage.setItem('refresh_token', data.refresh);

        // Retry the failed request with the new token
        error.config.headers.Authorization = `Bearer ${data.access}`;
        return apiClient.request(error.config);
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login'; 
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Define the API object
const api = {
  get: async (url) => {
    try {
      const response = await apiClient.get(url);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  },

  post: async (url, data) => {
    try {
      let headers = {};

      // Check if the data is an instance of FormData
      if (data instanceof FormData) {
        headers = { 'Content-Type': 'multipart/form-data' };
      } else {
        headers = { 'Content-Type': 'application/json' };
      }

      const response = await apiClient.post(url, data, { headers });
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  },

  put: async (url, data) => {
    try {
      let headers = {};

      
      if (data instanceof FormData) {
        headers = { 'Content-Type': 'multipart/form-data' };
      } else {
        headers = { 'Content-Type': 'application/json' };
      }
      
      const response = await apiClient.put(url, data, { headers });
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  },

  delete: async (url) => {
    try {
      const response = await apiClient.delete(url);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  },
};

export default api;
