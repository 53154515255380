import React from 'react'
import NavBarThree from '../navbar/NavBarThree';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';

function JobSubmission() {

    const location = useLocation();
    const jobId = location.state?.jobId || 'N/A';
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/jobs');
    };


    return (
        <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
            <NavBarThree />

            <div className="relative flex-grow flex justify-center">
                <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg w-full max-w-full h-full overflow-y-auto mx-4 md:mx-8  pb-8 md:pb-2 xl:pb-20">
                    <div className='bg-gray-100 flex py-4 rounded-xl w-full'>
                    </div>

                    <div className="relative flex justify-center items-center md:h-[60%]">
                        <div className="bg-transparent  md:p-4 p-2 w-full max-w-full h-full overflow-y-auto">

                            <div className="font-serif text-black text-base md:text-lg mt-4 mb-4">
                                <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold font-serif text-black mb-2"> Application Submitted!!! </h2>
                            </div>
                            <div className="text-center p-4">
                                <img src="/assets/successorange.png" alt="Welcome" className="mx-auto mb-1 w-[30%] md:w-[10%] h-auto" />
                            </div>
                            <div>
                                <h2 className='text-sm md:text-2xl xl:text-3xl font-serif text-gray mb-2"'>Your Application for JOB ID: <span className='font-bold text-orange-700'>{jobId}</span> has been submitted successfully.</h2>
                                <h2 className='text-sm md:text-2xl xl:text-3xl font-serif text-gray mb-2"'>We have sent your details to the respective partner</h2>
                                <div className='mt-4'>
                                    <p className='font-serif text-xs md:text-lg  xl:text-xl text-gray mb-2"'>If you dont't hear back, please consider your application unsuccesful.</p>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="mt-8 mb-8 md:flex justify-center md:justify-between">
                                <button type="button" className="md:ml-32 bg-orange-700 text-white mx-8 py-1.5 px-3 md:px-28 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif" onClick={handleClick}>
                                    Back to Jobs
                                </button>
                                <a href="/driverdashboard">
                                <button type="button" className="md:mr-32 bg-gray-500 text-white mx-2 py-1.5 px-6 md:px-28 rounded-lg hover:bg-gray-800 text-xs xl:text-lg font-serif">
                                    Home
                                </button>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default JobSubmission