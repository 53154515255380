import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../footer/Footer';
import api from '../../services/api';
import Spinner from '../spinner/Spinner';
import NavBarThree from '../navbar/NavBarThree';


function JobEditForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [formData, setFormData] = useState({
    vehicle_make: '',
    vehicle_model: '',
    preferred_work_area: '',
    min_age: '',
    max_age: '',
    work_days: '',
    requirements: '',
    vehicle_photo: null,
  });
  const [previewVehiclePhoto, setPreviewVehiclePhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchJob = async () => {
      if (!id) return;
      try {
        const response = await api.get(`/api/jobs/${id}/`);
        setFormData({
          vehicle_make: response.vehicle_make || '',
          vehicle_model: response.vehicle_model || '',
          preferred_work_area: response.preferred_work_area || '',
          min_age: response.min_age || '',
          max_age: response.max_age || '',
          work_days: response.work_days || '',
          requirements: response.requirements || '',
          vehicle_photo: response.vehicle_photo || '',
        });
        setLoading(false);
      } catch (error) {
        setError('Failed to load job information.');
        setLoading(false);
      }
    };
    fetchJob();
  }, [id]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const [makesResponse, modelsResponse, areasResponse] = await Promise.all([
          api.get('/api/vehicle-makes/'),
          api.get('/api/vehicle-models/'),
          api.get('/api/work-areas/'),
        ]);

        setVehicleMakes(makesResponse);
        setVehicleModels(modelsResponse);
        setWorkAreas(areasResponse);
      } catch (error) {
        console.error('Error fetching metadata:', error.response?.data || error.message);
      }
    };
    fetchMetadata();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

      if (file && allowedFormats.includes(file.type)) {
        setPreviewVehiclePhoto(URL.createObjectURL(file));
        setFormData({ ...formData, [name]: file });
      } else {
        setError('Please upload a valid image file (PNG, JPEG, JPG, or SVG).');
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    const formPayload = new FormData();
    for (const key in formData) {
      if (key === 'vehicle_photo') {
        if (formData[key] instanceof File) {
          formPayload.append(key, formData[key]);
        }
      } else if (formData[key] !== null && formData[key] !== '') {
        formPayload.append(key, formData[key]);
      }
    }

    try {
      await api.put(`/api/jobs/${id}/`, formPayload);

      navigate(`/editjob/${id}`, {
        state: {
          successPopup: 'Job details updated successfully!',
        },
      });
    } catch (error) {
      console.error('Error updating job:', error.response?.data || error.message);

      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.non_field_errors) {
            setError(error.response.data.non_field_errors.join(' '));
          } else if (error.response.data && typeof error.response.data === 'object') {
            const errorMessages = Object.values(error.response.data).flat().join(' ');
            setError(errorMessages);
          } else {
            setError('An unexpected error occurred. Please try again.');
          }
        } else {
          setError('An error occurred. Please try again later.');
        }
      } else {
        setError('Network error. Please check your connection and try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }



  return (
    <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
      <NavBarThree />
      <div className="relative flex-grow flex justify-center items-center">
        <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg w-full max-w-full h-auto overflow-y-auto mx-4 md:mx-8">
          <div className='bg-gray-100 p-2 rounded-xl w-full'>
            <h1 className='font-serif font-bold py-2 md:px-4 px-2 text-xl md:text-2xl md:mr-4 xl:text-3xl'>Edit Job Information</h1>
          </div>
          {error && (
            <div className="flex justify-center items-center mt-3">
              <div className="error-alert bg-red-500 text-white p-2 text-xs font-serif w-[90%] md:w-1/2">
                {error}
              </div>
            </div>
          )}
          <div className='p-4'>
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6 md:grid-cols-2">

              <div>
                <label className="block text-sm font-semibold text-left px-2 font-serif">Vehicle Make</label>
                <select
                  name="vehicle_make"
                  value={formData.vehicle_make}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 font-serif text-sm rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                >
                  {vehicleMakes.map(make => (
                    <option key={make.id} value={make.id} className='font-serif text-xs'>
                      {make.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-semibold text-left px-2 font-serif">Vehicle Model</label>
                <select
                  name="vehicle_model"
                  value={formData.vehicle_model}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-sm font-serif shadow-sm focus:ring-orange-500 focus:border-orange-500"
                >
                  <option value="" className='font-serif text-sm'>Select Vehicle Model</option>
                  {vehicleModels
                    .filter((model) => model.make.id === parseInt(formData.vehicle_make))
                    .map((model) => (
                      <option key={model.id} value={model.id} className='font-serif text-xs'>
                        {model.name}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-semibold text-left md:px-2 font-serif">Preferred Work Area</label>
                <select
                  name="preferred_work_area"
                  value={formData.preferred_work_area}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 font-serif rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                >
                  {workAreas.map(area => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label className="block text-sm font-semibold text-left px-2 font-serif">Work Days</label>
                <input
                  type="text"
                  name="work_days"
                  value={formData.work_days}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 font-serif text-sm rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              <div>
                <label className="block text-sm font-semibold text-left px-2 font-serif">Minimum Age</label>
                <input
                  type="number"
                  name="min_age"
                  value={formData.min_age}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              <div>
                <label className="block text-sm font-semibold text-left px-2 font-serif">Maximum Age</label>
                <input
                  type="number"
                  name="max_age"
                  value={formData.max_age}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* Vehicle Photo Input */}
              <div className="col-span-2">
                <label className="block text-sm font-semibold text-left  font-serif mb-2">Vehicle Photo</label>
                {previewVehiclePhoto ? (
                  <img src={previewVehiclePhoto} alt="New Vehicle Preview" className="mb-2 h-24 w-24 object-cover" />
                ) : formData.vehicle_photo ? (
                  <img src={formData.vehicle_photo} alt="Current Vehicle" className="mb-2 h-24 w-24 object-cover" />
                ) : (
                  <p>No vehicle photo available</p>
                )}
                <input
                  type="file"
                  name="vehicle_photo"
                  onChange={handleInputChange}
                  accept="image/*"
                  className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-semibold text-left px-2 font-serif">Requirements</label>
                <textarea
                  name="requirements"
                  value={formData.requirements}
                  onChange={handleInputChange}
                  className="mt-1 block w-full font-serif text-xs p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              <div className="col-span-2 flex justify-end">
                <button
                  type="submit"
                  className="bg-orange-700 text-white font-serif text-xs py-2 px-6 rounded-md shadow-md hover:bg-orange-800"
                >

                  {isSubmitting ? "Saving Changes..." : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobEditForm;

