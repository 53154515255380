import React from 'react';
import { Link } from 'react-router-dom';
import NavBarTwo from '../navbar/NavBarTwo';
import Footer from '../footer/Footer';

function SignUp() {
    return (
        <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
            <div className='flex-grow'>
                <NavBarTwo />
                <div className="text-center xl:mt-10 mt-4 mb:mt-6 mb-2">
                    <h2 className="md:text-3xl text-xl xl:text-5xl font-bold text-white font-serif">Create Your Account</h2>
                </div>
                <div className="flex flex-col items-center justify-center w-full mb-8 mt-8 xl:mt-12 md:mt-12 md:flex-row md:space-x-20 xl:space-x-28 space-y-8 md:space-y-0">
                    <div className="bg-gray-200 bg-opacity-65 p-1 w-[60%] md:w-[35%] flex flex-col justify-center items-center rounded-xl md:h-[56vh]">
                        <img src="/assets/drivericon12.png" alt="Logo" className="w-[28%] mt-4" />
                        <h2 className="text-xl xl:text-4xl font-bold mt-2 text-center font-serif">Driver</h2>
                        <p className="text-gray-800 mt-2 text-center text-xs md:text-lg xl:text-3xl font-serif">
                            Are you looking for driving job?, <br /> Create a unique career profile! <br /> Dere.com
                        </p>
                        <Link to="/driverone">
                            <button className="md:mt-6 mt-3 w-40 xl:w-72 mb-8">
                                <img src="/assets/buttons/signupasadriver.png" alt="Sign up as Driver" className="w-[90%] h-auto" />
                            </button>
                        </Link>
                    </div>
                    <div className="bg-gray-200 bg-opacity-65 p-1 w-[60%] md:w-[35%] flex flex-col justify-center items-center rounded-xl md:h-[56vh]">
                        <img src="/assets/partnericon.png" alt="Logo" className="w-[32%]" />
                        <h2 className="text-xl font-bold text-center xl:text-4xl font-serif">Partner</h2>
                        <p className="text-gray-800 mt-2 text-center text-xs md:text-lg xl:text-3xl font-serif">
                            Are you looking for quality drivers, <br /> Advertise with us!
                        </p>
                        <Link to="/partnerone">
                            <button className="mt-6 w-40 xl:w-72 mb-8">
                                <img src="/assets/buttons/signupasapartner.png" alt="partner" className="w-[95%] h-auto" />
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default SignUp;
