// import React, { useState } from 'react';
// import { FaBars, FaTimes } from 'react-icons/fa';

// const NavbarTwo = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="w-full">
//       <header className="w-full flex justify-between items-center">
//         <div className="flex items-center py-2 w-[34%] bg-transparent xl:mt-5">
//           <a href="/">
//             <img src="/assets/clearwhitelogo.png" alt="Logo" className="md:h-16 xl:h-24 md:mt-4 md:px-4 px-1 ml-6 md:ml-12" />
//           </a>
//         </div>
//         <div className="md:flex hidden justify-end w-[66%] mt-7">
//           <nav className="space-x-6">
//             <a href="/" className="text-white px-4 ml-10 xl:text-2xl font-serif font-semibold">Home</a>
//             <a href="/jobs" className="text-gray-200 py-4 xl:text-2xl font-serif">Job Opportunities</a>
//             <a href="/" className="text-gray-200 py-4 xl:text-2xl font-serif">About Us</a>
//           </nav>
//           <div className="ml-auto space-x-8 px-4 xl:px-6">
//             <a href="/login" className="text-white font-serif xl:text-2xl py-4 font-semibold">Log In</a>
//             <a href="/signup" className="text-white font-serif py-4 xl:text-2xl font-semibold">Sign Up</a>
//             <a href='/postjob' className="bg-black text-white px-4 py-3 xl:px-6 xl:py-4 font-serif xl:text-2xl font-semibold mr-2">Post Job</a>
//           </div>
//         </div>
//         <div className="md:hidden flex items-center px-6">
//           <button onClick={toggleMenu} className="text-3xl font-serif font-thin text-white">
//             {isOpen ? <FaTimes /> : <FaBars />}
//           </button>
//         </div>
//       </header>
//       {isOpen && (
//         <div className="md:hidden fixed inset-0 bg-orange-700 z-50 flex flex-col items-center justify-center">
//           <button onClick={toggleMenu} className="absolute top-6 right-6 text-3xl text-black">
//             <FaTimes />
//           </button>
//           <nav className="flex flex-col space-y-6 text-2xl">
//             <a href="/" className="text-white font-serif font-semibold" onClick={toggleMenu}>Home</a>
//             <a href="/jobs" className="text-gray-200 font-serif" onClick={toggleMenu}>Job Opportunities</a>
//             <a href="/about" className="text-gray-200 font-serif" onClick={toggleMenu}>About Us</a>
//             <a href="/login" className="text-black font-bold font-serif" onClick={toggleMenu}>Log In</a>
//             <a href="/signup" className="text-black font-bold font-serif" onClick={toggleMenu}>Sign Up</a>
//             <a href="/postjob" className="bg-black text-white px-4 py-3 font-bold font-serif" onClick={toggleMenu}>Post Job</a>
//           </nav>
//         </div>
//       )}
//     </div>
//   );
// };

// export default NavbarTwo;


import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const NavbarTwo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const goToSectionWithoutScroll = (e) => {
    e.preventDefault();

    if (window.innerWidth < 768) {
      // On mobile, close the menu and navigate to the section via URL
      setIsOpen(false);
      window.location.href = '/#about-us-section';
    } else {
      // On larger screens, update the URL to navigate
      window.location.href = '/#about-us-section';
    }
  };

  return (
    <div className="w-full">
      <header className="w-full flex justify-between items-center">
        <div className="flex items-center  w-[34%] bg-transparent xl:mt-5">
          <a href="/">
            <img src="/assets/otherpagesmainlogo.png" alt="Logo" className="md:h-24 h-24 xl:h-32 md:px-4 px-1 ml-6 md:ml-12" />
          </a>
        </div>
        <div className="md:flex hidden justify-end w-[66%] mt-7">
          <nav className="space-x-6">
            <a href="/" className="text-white px-4 ml-10 xl:text-2xl font-serif font-semibold">Home</a>
            <a href="/jobs" className="text-gray-200 py-4 xl:text-2xl font-serif">Job Opportunities</a>
            <a href="/#about-us-section" className="text-gray-200 py-4 xl:text-2xl font-serif" onClick={goToSectionWithoutScroll}>About Us</a>
          </nav>
          <div className="ml-auto space-x-8 px-4 xl:px-6">
            <a href="/login" className="text-white font-serif xl:text-2xl py-4 font-semibold">Log In</a>
            <a href="/signup" className="text-white font-serif py-4 xl:text-2xl font-semibold">Sign Up</a>
            <a href='/postjob' className="bg-black text-white px-4 py-3 xl:px-6 xl:py-4 font-serif xl:text-2xl font-semibold mr-2">Post Job</a>
          </div>
        </div>
        <div className="md:hidden flex items-center px-6">
          <button onClick={toggleMenu} className="text-3xl font-serif font-thin text-white">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </header>

      {isOpen && (
        <div className="md:hidden fixed inset-0 bg-orange-700 z-50 flex flex-col items-center justify-center">
          <button onClick={toggleMenu} className="absolute top-6 right-6 text-3xl text-black">
            <FaTimes />
          </button>
          <nav className="flex flex-col space-y-6 text-2xl">
            <a href="/" className="text-white font-serif font-semibold" onClick={toggleMenu}>Home</a>
            <a href="/jobs" className="text-gray-200 font-serif" onClick={toggleMenu}>Job Opportunities</a>
            <a href="/#about-us-section" className="text-gray-200 font-serif" onClick={goToSectionWithoutScroll}>About Us</a>
            <a href="/login" className="text-black font-bold font-serif" onClick={toggleMenu}>Log In</a>
            <a href="/signup" className="text-black font-bold font-serif" onClick={toggleMenu}>Sign Up</a>
            <a href="/postjob" className="bg-black text-white px-4 py-3 font-bold font-serif" onClick={toggleMenu}>Post Job</a>
          </nav>
        </div>
      )}
    </div>
  );
};

export default NavbarTwo;

