import { useEffect, useState } from 'react';

const useAuthToken = () => {
    const [hasToken, setHasToken] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setHasToken(true);
        } else {
            setHasToken(false);
        }
    }, []);

    return hasToken;
};

export default useAuthToken;
