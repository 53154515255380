import React from "react";

function Footer() {
  const openTermsAndConditions = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.open("/terms-and-conditions", "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:flex max-h-screen bg-orange-700 md:mt-10 mt-5 xl:py-4 xl:px-16 py-2 px-8 z-40">
        {/* 1st div */}
        <div className="md:w-[50%] md:flex">
          <div className="flex flex-col items-center px-4">
            <img
              src="/assets/landingpagemainlogo.png"
              alt="Logo"
              className="md:h-20 xl:h-32 h-12 xl:mt-4 mt-1 "
            />
            <div className="flex mt-2 xl:px-6 px-4">
              <img
                src="/assets/mailicon.png"
                alt="Mail Icon"
                className="h-10 xl:h-12"
              />
              <img
                src="/assets/whatsapp.png"
                alt="WhatsApp Icon"
                className="h-10 xl:h-12"
              />
              <img src="/assets/X.png" alt="X Icon" className="h-10 xl:h-12" />
            </div>
          </div>
          <div className="flex flex-col items-center md:items-start xl:px-8 px-4 xl:mt-8 mt-5 xl:ml-32 md:ml-20">
            <h1 className="font-serif font-bold xl:text-3xl text-xl">
              USEFUL LINKS
            </h1>
            <div className="grid grid-cols-1 gap-x-4 items-center md:items-start mt-2">
              <div className="flex flex-col space-y-2 items-center md:items-start">
                <a href="/">
                  <h3 className="text-xs xl:text-lg font-semibold font-serif">
                    HOME
                  </h3>
                </a>
                <a href="/jobs">
                  <h3 className="text-xs xl:text-lg font-semibold font-serif">
                    FIND JOBS
                  </h3>
                </a>
                <a href="/postjob">
                  <h3 className="text-xs xl:text-lg font-semibold font-serif">
                    PARTNER WITH US
                  </h3>
                </a>
                {/* Add the Terms and Conditions link */}
                <a
                  href="/terms-and-conditions"
                  onClick={openTermsAndConditions}
                >
                  <h3 className="text-xs xl:text-lg font-semibold font-serif">
                    TERMS AND CONDITIONS
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd div */}
        <div className="md:w-[50%] flex items-center justify-center">
          <div className="flex flex-col items-start px-4 mt-5">
            <h1 className="font-serif font-bold xl:text-3xl text-xl mb-2 px-2">
              GET IN TOUCH
            </h1>
            <div className="flex justify-start mb-1">
              <img
                src="/assets/location.png"
                alt="Mail Icon"
                className="h-8 xl:h-12"
              />
              <span className="ml-1 text-xs xl:text-lg font-serif mt-3">
                NAIROBI,KENYA
              </span>
            </div>
            <div className="flex justify-start mb-1">
              <img
                src="/assets/call.png"
                alt="WhatsApp Icon"
                className="h-8 xl:h-12"
              />
              <span className="ml-1 text-xs xl:text-lg font-serif mt-3">
                +254702236510
              </span>
            </div>
            <div className="flex justify-start mb-1">
              <img
                src="/assets/sendmail.png"
                alt="X Icon"
                className="h-8 xl:h-12"
              />
              <span className="ml-1 text-xs xl:text-lg font-serif mt-3">
                {" "}
                support@kadereconect.com
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex md:hidden max-h-screen bg-orange-700 mt-5 py-2 z-40">
        <div className="w-[40%] flex flex-col">
          <img
            src="/assets/landingpagemainlogo.png"
            alt="Logo"
            className="w-[90%] mt-4 px-5"
          />
        </div>
        <div className="w-[60%] flex flex-col">
          <div className="flex flex-col items-start px-4 mt-5">
            <h1 className="font-serif font-bold text-lg">USEFUL LINKS</h1>
            <div className="flex flex-col mt-2 ml-4">
              <a href="/">
                <h3 className="text-xs xl:text-lg font-serif">HOME</h3>
              </a>
              <a href="/jobs">
                <h3 className="text-xs xl:text-lg font-serif mt-2">
                  FIND JOBS
                </h3>
              </a>
              <a href="/postjob">
                <h3 className="text-xs xl:text-lg font-serif mt-2">
                  PARTNER WITH US
                </h3>
              </a>
              {/* Add the Terms and Conditions link */}
              <a href="/terms-and-conditions" onClick={openTermsAndConditions}>
                <h3 className="text-xs xl:text-lg font-serif mt-2">
                  TERMS AND CONDITIONS
                </h3>
              </a>
            </div>
          </div>
          <div className="flex py-2 mt-2">
            <img src="/assets/mailicon.png" alt="Mail Icon" className="h-7" />
            <img
              src="/assets/whatsapp.png"
              alt="WhatsApp Icon"
              className="h-7"
            />
            <img src="/assets/X.png" alt="X Icon" className="h-7" />
            <img src="/assets/call.png" alt="Call Icon" className="h-7" />
            <img
              src="/assets/location.png"
              alt="Location Icon"
              className="h-7"
            />
          </div>
          <div className="flex flex-col items-start py-4">
            <h1 className="font-serif font-semibold text-sm text-left ml-6">
              2024 DERE.COM
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
