import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import NavBarThree from "../navbar/NavBarThree";
import Footer from "../footer/Footer";

const PostJob = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    vehicleMake: "",
    vehicleModel: "",
    workArea: "",
    workDays: "",
    minAge: "",
    maxAge: "",
    requirements: "",
    vehiclePhoto: null,
    termsAndConditions: false,
  });

  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission process

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [makesResponse, modelsResponse, areasResponse] = await Promise.all([
          api.get("/api/vehicle-makes/"),
          api.get("/api/vehicle-models/"),
          api.get("/api/work-areas/"),
        ]);

        setVehicleMakes(makesResponse);
        setVehicleModels(modelsResponse);
        setWorkAreas(areasResponse);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        alert("Please upload a valid image file (png, jpg, jpeg, or gif).");
        return;
      }

      if (file.size > maxSize) {
        alert("File size exceeds the 5MB limit. Please choose a smaller file.");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        vehiclePhoto: file,
      }));
      setFileName(file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show loading spinner

    const form = new FormData();
    form.append("vehicle_make", formData.vehicleMake);
    form.append("vehicle_model", formData.vehicleModel);
    form.append("preferred_work_area", formData.workArea);
    form.append("work_days", formData.workDays);
    form.append("min_age", formData.minAge);
    form.append("max_age", formData.maxAge);
    form.append("requirements", formData.requirements);

    if (formData.vehiclePhoto) {
      form.append("vehicle_photo", formData.vehiclePhoto);
    }

    form.append("terms_and_conditions", formData.termsAndConditions);

    try {
      await api.post("/api/jobs/", form);

      // Reset the form after successful submission
      setFormData({
        vehicleMake: "",
        vehicleModel: "",
        workArea: "",
        workDays: "",
        minAge: "",
        maxAge: "",
        requirements: "",
        vehiclePhoto: null,
        termsAndConditions: false,
      });

      navigate("/welcomepostjob"); // Redirect without alert
    } catch (error) {
      console.error("Error posting job", error);
      alert("Failed to post job. Please try again.");
    } finally {
      setIsSubmitting(false); // Hide loading spinner
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-cover w-full" style={{ backgroundImage: "url(/assets/background3.svg)" }}>
      <NavBarThree />
      <div className="flex-grow flex flex-col items-center mt-4 mb-4">
        <div className="w-full flex justify-center items-start px-4">
          <div className="md:flex bg-white bg-opacity-50 rounded-xl shadow-lg w-full" style={{ height: "90%" }}>
            <div className="bg-gray-200 p-3 md:w-1/3 flex flex-col justify-center md:rounded-xl md:shadow-lg">
              <div className="text-center">
                <h2 className="text-2xl xl:text-3xl font-bold font-serif text-black">Post a Job</h2>
                <p className="text-black mt-2 text-center font-serif text-lg xl:text-2xl">
                  Please add your job details
                </p>
              </div>
            </div>
            {/* vehicle make & model */}
            <div className="bg-white bg-opacity-50 p-3 md:w-2/3 flex flex-col md:rounded-r-xl">
              <form className="space-y-4 md:space-y-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-2 mt-2 md:mr-16">
                  <div className="flex flex-col">
                    <label htmlFor="vehicleMake" className="mb-1 text-black uppercase text-left px-2 xl:text-lg text-xs font-serif">
                      Vehicle Make <span className="text-red-500 ml-1">*</span>
                    </label>
                    <select
                      id="vehicleMake"
                      name="vehicleMake"
                      className="p-2 text-sm md:text-xs xl:text-lg font-serif border border-gray-300 rounded-lg w-full"
                      value={formData.vehicleMake}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Vehicle Make</option>
                      {vehicleMakes.map((make) => (
                        <option key={make.id} value={make.id}>
                          {make.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="vehicleModel" className="mb-1 text-black uppercase text-left px-2 xl:text-lg text-xs font-serif">
                      Vehicle Model <span className="text-red-500 ml-1">*</span>
                    </label>
                    <select
                      id="vehicleModel"
                      name="vehicleModel"
                      className="p-2 text-sm md:text-xs xl:text-lg font-serif border border-gray-300 rounded-lg w-full"
                      value={formData.vehicleModel}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Vehicle Model</option>
                      {vehicleModels
                        .filter((model) => model.make.id === parseInt(formData.vehicleMake))
                        .map((model) => (
                          <option key={model.id} value={model.id}>
                            {model.name}
                          </option> 
                        ))}
                    </select>
                  </div>
                </div>
                {/* preffered work area */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2 md:mr-16">
                  <div className="flex flex-col">
                    <label htmlFor="workArea" className="mb-1 text-black uppercase text-left px-2 text-xs xl:text-lg font-serif">
                      Preferred Work Area <span className="text-red-500 ml-1">*</span>
                    </label>
                    <select
                      id="workArea"
                      name="workArea"
                      className="p-2 text-sm md:text-xs border border-gray-300 xl:text-lg font-serif rounded-lg w-full"
                      value={formData.workArea}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Work Area</option>
                      {workAreas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="workDays" className="mb-1 text-black uppercase text-left px-2 text-xs xl:text-lg font-serif">
                      Work Days <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="number"
                      id="workDays"
                      name="workDays"
                      value={formData.workDays || ""}
                      onChange={handleChange}
                      className="p-2 text-sm md:text-xs border border-gray-300 xl:text-lg font-serif rounded-lg w-full"
                      required
                    />
                  </div>
                </div>
                {/* age */}
                <div className="grid grid-cols-2 gap-2 mt-2 md:mr-16">
                  <div className="flex flex-col">
                    <label htmlFor="minAge" className="mb-1 text-black uppercase text-left px-2 text-xs xl:text-lg font-serif">
                      Min Age
                    </label>
                    <input
                      type="number"
                      id="minAge"
                      name="minAge"
                      placeholder="Min Age 18"
                      className="p-2 text-sm md:text-xs border border-gray-300 xl:text-lg font-serif rounded-lg w-full"
                      value={formData.minAge}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="maxAge" className="mb-1 text-black uppercase text-left px-2 text-xs xl:text-lg font-serif">
                      Max Age
                    </label>
                    <input
                      type="number"
                      id="maxAge"
                      name="maxAge"
                      className="p-2 text-sm md:text-xs border border-gray-300 xl:text-lg font-serif rounded-lg w-full"
                      value={formData.maxAge}
                      onChange={handleChange}
                    />
                  </div>
                </div>


                {/* Vehicle Photo */}
                <div className="grid grid-cols-1 gap-2 mt-2 md:mr-16">
                  <div className="flex items-center mt-4">
                    <label

                      htmlFor="vehiclePhoto"
                      className="md:ml-1 bg-gray-500 shadow-md border border-gray-500 px-3 py-2 md:px-6 md:py-2 text-white text-xs xl:text-sm md:text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      Choose File


                      <input

                        type="file"
                        id="vehiclePhoto"
                        name="vehiclePhoto"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>

                    <span className="ml-4 text-black text-xs">
                      {fileName || "Vehicle Photo"}{" "}
                      {/* Display selected file name */}
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                  </div>
                </div>
                {/* requirements */}
                <div className="grid grid-cols-1 gap-2 md:mr-16">
                  <div className="flex flex-col">
                    <label htmlFor="requirements" className="mb-1 text-black uppercase text-left px-2 text-xs xl:text-lg font-serif">
                      Requirements <span className="text-red-500 ml-1">*</span>
                    </label>
                    <textarea
                      id="requirements"
                      name="requirements"
                      className="p-2 text-sm md:text-xs xl:text-lg font-serif border border-gray-300 rounded-lg w-full"
                      placeholder="Add requirements"
                      value={formData.requirements}
                      onChange={handleChange}
                      rows="4"
                      required
                    />
                  </div>
                </div>

                {/* Terms and Condition */}
                <div className="mt-4">
                  <div className="flex md:items-center md:space-x-2">
                    <input
                      type="checkbox"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      className="h-4 w-4 text-orange-700 border-gray-300 rounded focus:ring-orange-600"
                      checked={formData.termsAndConditions}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          termsAndConditions: e.target.checked,
                        })
                      }
                      required
                    />
                    <label htmlFor="termsAndConditions" className="font-serif">
                      <span className="text-black-600 ml-1 md:mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif">
                        I AGREE TO THE{" "}
                        <span className="text-orange-600">
                          TERMS AND CONDITIONS
                        </span>{" "}
                        AND{" "}
                        <span className="text-orange-600">PRIVACY POLICY</span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="flex justify-end xl:mt-4 xl:mb-4 mt-2 mb-2 mr-8">
                  <button
                    type="submit"
                    className="bg-orange-700 text-white py-2 px-5 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Posting Job ..." : "Post Job"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PostJob;
