import React, { useState } from "react";
import Footer from "../footer/Footer";
import { useAuth } from "../Auth/AuthProvider";
import axios from "axios";
import { FiX } from 'react-icons/fi';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

// forgot password component
function ForgotPasswordPopup({ onClose, onSubmit }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        const result = await onSubmit();

        if (result) {
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 h-auto">
            <div className="bg-red-700 py-3 px-8 rounded-lg shadow-lg">
                <h3 className="text-sm xl:text-xl font-serif font-bold mb-1 underline">Reset Password</h3>
                <p className="text-sm  xl:text-xl font-serif">
                    This will send an email with <br /> instructions to reset your password!
                </p>
                <div className="flex justify-center items-center space-x-4 mt-1">
                    <button
                        onClick={onClose}
                        disabled={isLoading}
                        className={`text-xs xl:text-xl text-black font-semibold font-serif px-4 py-1 ${isLoading ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-400'
                            }`}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleClick}
                        disabled={isLoading}
                        className={`text-black xl:text-xl font-semibold font-serif text-xs  px-4 py-1 ${isLoading ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-400'
                            }`}
                    >
                        {isLoading ? 'Sending request...' : 'Okay'}
                    </button>
                </div>
            </div>
        </div>
    );
}

function Login() {
    const { loginAction } = useAuth();
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleRoleChange = (selectedRole) => {
        setRole(selectedRole);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!role) {
            setErrorMessage("Please select a role to continue.");
            setIsSubmitting(false);
        }

        const loginData = { email, password, role };

        try {
            await loginAction(loginData, setErrorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleForgotPassword = async () => {
        try {
            // await axios.post("http://127.0.0.1:8000/api/password-reset/", { email });
            await axios.post("https://derebackend-1.onrender.com/api/password-reset/", { email });

            setForgotPasswordMessage("Password reset link has been sent to your email address.");
            setShowForgotPasswordPopup(false);
        } catch (error) {
            if (error.response && error.response.data.email) {
                setForgotPasswordMessage(error.response.data.email[0]);
            } else {
                setForgotPasswordMessage("Error sending password reset email. Please try again.");
            }

            setShowForgotPasswordPopup(false);
        }
    };


    return (
        <div className="flex flex-col bg-cover" style={{ backgroundImage: "url(/assets/background2.0.png)" }} >
            <div className="flex flex-grow w-full h-screen relative">
                <div className="w-full absolute md:hidden block bottom-0 left-0 z-20">
                    <img src="/assets/car55.png" alt="Car" className="w-[100%]" />
                </div>
                <div className="w-[45%] md:w-[55%] flex flex-col items-center relative">
                    <div className="w-full h-full flex relative z-10">
                        <div className="w-[5%] hidden md:block bg-transparent"></div>
                        <div className="md:w-[55%] w-[98%] bg-orange-700 flex flex-col items-center">
                            <img
                                src="/assets/landingpageogo.png"
                                alt="Logo"
                                className="h-24 xl:h-32 mb-4 mt-8 px-4"
                            />
                            <h1 className="text-2xl text-white xl:text-4xl px-2 font-bold font-serif">
                                Already Have
                                <br /> an Account?
                            </h1>
                        </div>
                        <div className="w-[40%] hidden md:block bg-transparent"></div>
                    </div>
                    <div className="w-full absolute hidden md:block bottom-0 left-0 pt-20 z-20">
                        <img src="/assets/car55.png" alt="Car" className="w-[90%]" />
                    </div>
                </div>
                {/* login form */}
                <div className="md:w-[45%] w-[55%] flex flex-col items-start justify-start bg-opacity-0 md:px-8 md:mt-2 mt-8 md:py-10 relative">
                    <div className="py-4 md:w-[60%] flex justify-end">
                        <img src="/assets/login2.png" alt="Track" className="w-[30%]" />
                    </div>
                    <div className="bg-white bg-opacity-50 p-2 md:p-5 w-[96%] mx-1 md:w-[70%] flex flex-col md:rounded-xl">
                        {/* Forgot Password Popup */}
                        {showForgotPasswordPopup && (
                            <ForgotPasswordPopup
                                onClose={() => setShowForgotPasswordPopup(false)}
                                onSubmit={handleForgotPassword}
                            />
                        )}
                        {/* Display forgot password message */}
                        {forgotPasswordMessage && (
                            <div className="fixed top-24 text-left bg-red-700 md:left-2/5 md:transform md:-translate-x-1/2 mr-2 text-xs md:text-sm xl:text-xl font-serif px-2 md:px-4 py-2 rounded-lg shadow-lg flex items-center justify-between">
                                <span>{forgotPasswordMessage}</span>
                                <button
                                    className="ml-2 font-bold text-black hover:text-gray-300"
                                    onClick={() => setForgotPasswordMessage('')}
                                >
                                    <FiX size={20} />
                                </button>
                            </div>
                        )}

                        <h2 className="font-serif font-bold text-2xl xl:text-3xl my-2 md:my-4">
                            Sign in
                        </h2>
                        <form
                            className="space-y-3 xl:py-6 w-[100%] xl:px-12 md:px-6"
                            onSubmit={handleSubmit}
                        >
                            <div className="grid grid-cols-1 xl:gap-5 gap-2 md:gap-3">
                                <div className="flex flex-col items-start">
                                    <label
                                        htmlFor="email"
                                        className="mb-1 text-black uppercase xl:text-lg text-xs font-serif px-2"
                                    >
                                        EMAIL
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="p-2 w-[100%] xl:text-lg text-xs border border-gray-300 rounded-lg"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col items-start">
                                    <label
                                        htmlFor="password"
                                        className="mb-1 text-black uppercase text-xs xl:text-lg font-serif px-2"
                                    >
                                        PASSWORD
                                    </label>
                                    <div className="relative w-full"> {/* Wrap input and icon in a relative div */}
                                        <input
                                            type={showPassword ? "text" : "password"} // Conditionally change input type
                                            id="password"
                                            name="password"
                                            className="p-2 w-full text-xs xl:text-lg border border-gray-300 rounded-lg"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {/* Icon button to toggle visibility */}
                                        <button
                                            type="button"
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-red"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <AiFillEyeInvisible size={20} className="text-gray-500"  /> : <AiFillEye size={20} className="text-gray-800" />}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col items-end">
                                    <button
                                        type="button"
                                        className="text-black text-xs underline font-serif"
                                        onClick={() => setShowForgotPasswordPopup(true)}
                                    >
                                        Forgot Password?
                                    </button>
                                </div>
                                {/* Role checkboxes */}
                                <div className="flex items-start mt-2">
                                    <input
                                        type="checkbox"
                                        id="driver"
                                        name="role"
                                        className={`xl:text-lg text-xs border text-black border-gray-300 xl:w-6 xl:h-6 w-4 h-4 bg-opacity-60 ${role === "driver" ? "bg-black border-transparent" : "bg-transparent"}`}
                                        checked={role === "driver"}
                                        onChange={() => handleRoleChange(role === "driver" ? "" : "driver")}
                                    />
                                    <label
                                        htmlFor="driver"
                                        className="ml-2 text-black uppercase text-xs xl:text-lg font-serif"
                                    >
                                        DRIVER
                                    </label>
                                </div>
                                <div className="flex items-start">
                                    <input
                                        type="checkbox"
                                        id="partner"
                                        name="role"
                                        className={`xl:text-lg text-xs border text-black border-gray-300 xl:w-6 xl:h-6 w-4 h-4 bg-opacity-60 ${role === "partner" ? "bg-black border-transparent" : "bg-transparent"}`}
                                        checked={role === "partner"}
                                        onChange={() => handleRoleChange(role === "partner" ? "" : "partner")}
                                    />
                                    <label
                                        htmlFor="partner"
                                        className="ml-2 text-black uppercase text-xs xl:text-lg font-serif"
                                    >
                                        PARTNER
                                    </label>
                                </div>
                            </div>
                            {/* Display error message if it exists */}
                            {errorMessage && (
                                <div className="error-popup">{errorMessage}</div>
                            )}
                            <div className="flex justify-center md:py-2 z-50">
                                <button
                                    type="submit"
                                    className="bg-orange-700 text-white py-1.5 px-7 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif"
                                >
                                    {isSubmitting ? "Logging in ..." : "Login"}
                                </button>
                            </div>
                            <div className="flex justify-center mb-0">
                                <p className="text-black text-xs xl:text-lg">
                                    Do not have an account?
                                    <a href="/signup" className="underline ml-1">Sign Up</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Login;
