import React from "react";
import Footer from "../footer/Footer";
import TermsNavbar from "../navbar/TermsNavBar";

function TermsAndCondtions() {
  const openTermsAndConditions = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.open("/privacy-policy", "_blank", "noopener,noreferrer");
  };
  return (
    <div>
      <TermsNavbar />
      <div className="min-h-screen bg-white py-10 px-4 md:px-20 lg:px-40 text-gray-800">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-3xl font-bold mb-4 text-center">TERMS OF USE</h1>
          <section className="space-y-6 text-justify">
            <h2 className="text-xl font-semibold mb-2">
              1. ACCEPTANCE OF THE TERMS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                These Terms of Use (the “Terms”) constitute a binding and
                enforceable legal contract between [Your Company Name] (the
                “Administrator”, “we”, “us”) and you. Please read these Terms
                carefully. Your access and use of the KadereConect.com website
                and mobile applications, as well as any service, content, and
                data available via them (together, the “Service” or the
                “Platform”) are governed by these Terms.
              </li>
              <li>
                If you do not agree with any part of these Terms, or if you are
                not eligible or authorized to be bound by the Terms, then do not
                access or use the Service.
              </li>
              <li>
                Please also review{" "}
                <a
                  href="/privacy-policy"
                  onClick={openTermsAndConditions}
                  className="text-orange-500 hover:underline"
                >
                  Privacy Policy
                </a>
                <span className="block mt-2">
                  The terms of the Privacy Policy and other supplemental terms,
                  rules, policies, or documents that may be posted on the
                  Platform from time to time are hereby expressly incorporated
                  herein by reference. We reserve the right, in our sole
                  discretion, to make changes or modifications to these Terms at
                  any time and for any reason with or without prior notice.
                </span>
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              2. IMPORTANT DISCLAIMERS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                ALL SERVICES RENDERED BY THE ADMINISTRATOR ARE RENDERED “AS IS”,
                “AS AVAILABLE” AND “WITH ALL FAULTS”, AND THE ADMINISTRATOR
                DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING
                WITHOUT LIMITATION ANY GUARANTEES REGARDING CONDITION, QUALITY,
                LIFE DURATION, PERFORMANCE, ACCURACY, RELIABILITY, COMMERCIAL
                VALUE AND SUITABILITY FOR SPECIFIC PURPOSES.
              </li>
              <li>
                THE ADMINISTRATOR HAS NO CONTROL OVER AND DOES NOT GUARANTEE THE
                EXISTENCE, QUALITY, SAFETY OR LEGALITY OF JOB POSTINGS OR
                VEHICLES PROVIDED BY PARTNERS; THE TRUSTWORTHINESS OR ACCURACY
                OF INFORMATION PROVIDED BY USERS; OR THAT A USER WILL ACTUALLY
                COMPLETE A TRANSACTION.
              </li>
              <li>
                YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR SAFETY
                AND YOU UNDERSTAND THAT YOU SHOULD MEET WITH OTHER INDIVIDUALS
                FOR COMPLETION OF A TRANSACTION ONLY IN SAFE PUBLIC PLACES IN
                DAYLIGHT HOURS. THE ADMINISTRATOR DISCLAIMS ANY RESPONSIBILITY
                FOR USER’S INTERACTION WITH ANY INDIVIDUAL OR ORGANIZATION.
              </li>
            </ol>
            <h2 className="text-xl font-semibold mb-2">
              3. ACCOUNT REGISTRATION
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                In order to use certain features of the Service, you may need to
                register an account on the Platform (the “Account”) and provide
                certain information about yourself as prompted by the
                registration form.
              </li>
              <li>
                You may create an Account as an individual or as an authorized
                representative of a company.
              </li>
              <li>
                You can register only one Account. If more than one person
                accesses their Account from the same device, we may request
                proof of identity to avoid duplicate accounts.
              </li>
              <li>
                You acknowledge that you are solely responsible for safeguarding
                and maintaining the confidentiality of access details to your
                Account and that you are fully responsible and liable for any
                activity performed using your Account access details.
              </li>
              <li>
                You hereby represent and warrant to us that you have reached the
                age of majority or are accessing the Service under the
                supervision of a parent or legal guardian. Users who are minors
                in their jurisdiction (generally under the age of 18) must have
                permission from, and be directly supervised by, their parent or
                guardian to use the Service.
              </li>
              <li>
                We reserve the right to suspend or terminate your Account, or
                your access to the Service, with or without notice to you, in
                the event that you breach these Terms.
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">4. SERVICES</h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                The Platform connects skilled drivers with employers seeking
                reliable candidates.
                </li>
                <li>
                Users can create profiles, browse job
                postings, and apply directly for driving positions
                </li> 
                <li>
                The
                Administrator is not a party to any employment contracts or
                agreements between users and employers
                </li> 
                <li>
                The actual agreements are
                solely between the users and the partners, and the Administrator
                is not liable for any issues arising from those agreements.
                </li> 
                <li>
                We
                do not guarantee job placements or the authenticity of job
                listings.
                </li> 
                
              
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              5. USER RESPONSIBILITIES
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <p>By using the Platform, you agree to:</p>
              <li>
                Provide accurate, complete, and up-to-date information in your
                profile and job applications. 
                <li>
                Conduct yourself professionally in
                all interactions with other users and employers.
                  </li>
                  <li>
                  Verify the
                legitimacy of job postings and the qualifications of candidates
                before engaging in any transactions or agreements.
                  </li>
                  <li>
                  Not post
                  false, misleading, or infringing content.
                  </li>
                  
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">
              6. JOB POSTING AND APPLICATIONS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                Job postings will remain open for a maximum of two weeks. After
                this period, partners must pay to repost the position, as it is
                expected that they would have received the necessary
                applications within this timeframe.
              </li>
              <li>
                For drivers, personal information and application documents will
                be stored for a maximum of two weeks. After this period, drivers
                are required to re-upload their documents before proceeding with
                further applications.
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">
              7. POSTING OF ANNOUNCEMENTS BY USERS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                Users shall provide any documents confirming the legitimacy of
                their announcements and identity documents upon request.
              </li>
              <li>
                Users must provide precise and complete information about their
                job announcements.
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">8. FEES</h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                The Platform may be free to access, but certain services may
                incur fees. Fees are disclosed on the Platform and are subject
                to change. We reserve the right to change the fees and
                acceptable payment methods at any time. All fees are
                non-refundable unless otherwise required by law.
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">
              9. USER REPRESENTATIONS AND WARRANTIES
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                You represent that you have the legal capacity to enter into
                these Terms. If you register as a representative of a legal
                entity, you are authorized to do so.
              </li>
              <li>
                You will provide true, accurate, and complete information in
                your Account and will keep it updated.
              </li>
              <li>
                You will notify the Administrator of any unauthorized access to
                your Account.
              </li>
              <li>
                You will use the Service in accordance with applicable laws and
                will not post any false or misleading informati
              </li>
              <li>
                You will not use the Service for any illegal or unauthorized
                purpose or to post prohibited content.
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">10. INDEMNITY</h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                You agree to indemnify and hold the Administrator and its
                affiliates harmless from any claims or demands arising out of
                your use of the platform , your user content or your violation
                of these terms as well as those made by any third party arising
                from your use of the Platform, your User Content, or your
                violation of these Terms.
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              11. LIMITATION OF LIABILITY
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM
                THESE TERMS OR YOUR USE OF THE SERVICE. OUR LIABILITY IS LIMITED
                TO THE AMOUNTS YOU HAVE PAID TO US IN THE SIX (6) MONTHS PRIOR
                TO THE CLAIM.
              </li>
              <li>
                Access to and use of the Service are at your own risk, and you
                will be solely responsible for any damage to your computing
                system or loss of data resulting from your use
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">
              12. INTELLECTUAL PROPERTY RIGHTS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                You retain ownership of your User Content. However, by
                submitting User Content, you grant the Administrator a
                non-exclusive, worldwide, perpetual license to use, display, and
                distribute such content in connection with the Service.
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              13. SERVICE AND PARTNER LIABILITY DISCLAIMER
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                The Administrator is not responsible for the actions, decisions,
                or performance of any partner or employer utilizing the
                Platform, including the quality, safety, legality, or condition
                of vehicles provided
              </li>
              <li>
                Users are encouraged to conduct their own due diligence before
                entering into agreements with partners.
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              14. DISPUTE RESOLUTION
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                Any disputes arising from these Terms shall be resolved through
                binding arbitration in accordance with the NCIA Arbitration
                Rules. The arbitration shall be conducted in English
              </li>
            </ol>

            <h2 className="text-xl font-semibold mt-8 mb-2">
              15. GOVERNING LAW
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                These Terms shall be governed by the laws of Kenya. Any legal
                action must be initiated within one year after the cause of
                action arises
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">
              16. MISCELLANEOUS PROVISIONS
            </h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
                If any provision of these Terms is found to be invalid, the
                remaining provisions shall continue to be in effect.
              </li>
              <li>
                We may assign our rights and obligations under these Terms at
                our discretion.
              </li>
              <li>
                If any provision of these Terms is found to be invalid, the
                remaining provisions shall continue to be in effect.
              </li>
            </ol>
            <h2 className="text-xl font-semibold mt-8 mb-2">17. CONTACT</h2>
            <ol className="list-decimal space-y-4 pl-6">
              <li>
              For any questions or notices under these Terms, you may contact us at:{" "}
            <a
              href="mailto:support@kadereconect.com"
              className="text-orange-500 underline"
            >
              support@kadereconect.com
            </a>
              </li>
            </ol>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndCondtions;
