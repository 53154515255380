import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarTwo from '../navbar/NavBarTwo';
import Footer from '../footer/Footer';


function PartnerFormOne() {
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    id_number: '',
    company_number: '',
    company_name: '',
    password: '',
    confirm_password: '',
  });


  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if (!formData.first_name || !formData.last_name || !formData.email || !formData.id_number || !formData.password || !formData.confirm_password ) {
      return 'Please fill in all required fields.';
    }
    if (formData.password !== formData.confirm_password) {
      return 'Passwords do not match.';
    }
    return null;
  };

  const handleNextClick = () => {
    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    // Clear any previous error and navigate to the next step
    setError('');
    navigate('/partnertwo', { state: { formData } });
  };


  return (
    <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
      {/* navbar */}
      < NavBarTwo />

      <div className="flex flex-col flex-grow items-center">
        <div className="text-center xl:mt-8 mt-4 mb-4">
          <h2 className="md:text-3xl xl:text-5xl text-xl font-bold text-white font-serif">Create Your Partner Account</h2>
          <p className="text-white xl:text-2xl xl:mt-2 font-serif">Reach top drivers today</p>
        </div>
        <div className="flex-grow w-full flex justify-center items-start px-4 md:px-8">
          <div className="md:flex bg-white bg-opacity-50 rounded-xl shadow-lg w-full">
            {/* Step One Section */}
            <div className="bg-gray-200 p-5 md:w-1/3 flex flex-col justify-center md:rounded-xl shadow-lg">
              <div className="text-center mt-2">
                <h2 className="md:text-2xl text-xl xl:text-4xl font-bold font-serif text-black">Step One</h2>
                <h2 className="md:text-2xl text-xl xl:text-3xl font-bold font-serif text-black">Personal Information</h2>
                <p className="text-black mt-1 text-center xl:text-xl font-serif text-xs md:text-lg">
                  This is information pertaining to <br /> you as a represenative of your <br />company
                </p>
              </div>
            </div>
            {/* Form Section */}
            <div className="bg-white bg-opacity-50 md:p-5 p-2 md:w-2/3 flex flex-col md:rounded-r-xl">
              <form className="space-y-3 xl:py-6">
              {error && <p className="text-red-500 font-serif text-sm">{error}</p>}
                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="firstName" className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">First Name<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="firstName" name="first_name" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.first_name} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col ml-2">
                    <label htmlFor="lastName" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Last Name<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="lastName" name="last_name" className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.last_name} onChange={handleChange} required />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="email" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Email<span className='px-1 text-red-700'>*</span></label>
                    <input type="email" id="email" name="email" placeholder='example@gmail.com' className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.email} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col ml-2">
                    <label htmlFor="idNumber" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">ID Number<span className='px-1 text-red-700'>*</span></label>
                    <input type="text" id="idNumber" name="id_number" className="p-1.5 text-xs border xl:text-lg border-gray-300 rounded-lg" value={formData.id_number} onChange={handleChange} required />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="companyNumber" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Company Number</label>
                    <input type="text" id="companyNumber" name="company_number" className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.company_number} onChange={handleChange} />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="companyName" className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Company Name</label>
                    <input type="text" id="companyName" name="company_name" className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.company_name} onChange={handleChange} />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-col">
                    <label htmlFor="password" className="mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif">Password<span className='px-1 text-red-700'>*</span></label>
                    <input type="password" id="password" name="password" className="p-1.5 text-xs xl:text-lg border border-gray-300 rounded-lg" value={formData.password} onChange={handleChange} required />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="confirmPassword" className="mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif">Confirm Password<span className='px-1 text-red-700'>*</span></label>
                    <input type="password" id="confirmPassword" name="confirm_password" className="p-1.5 xl:text-lg text-xs border border-gray-300 rounded-lg" value={formData.confirm_password} onChange={handleChange} required />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button type="button" className="bg-orange-700 text-white py-1.5 px-7 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif" onClick={handleNextClick}>
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PartnerFormOne;



