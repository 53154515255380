import React from 'react';
import { useLocation, useParams, Navigate } from 'react-router-dom';
import JobSeeker from '../WelcomePage/JobSeeker'; 
import WelcomePartner from '../WelcomePage/WelcomePartner'; 

const EmailVerification = () => {
  const { verificationCode } = useParams(); 
  const location = useLocation(); 

  // Parse query params
  const searchParams = new URLSearchParams(location.search);
  const role = searchParams.get('role'); 

  if (role === 'partner') {
    return <WelcomePartner verificationCode={verificationCode} />;
  } else if (role === 'driver') {
    return <JobSeeker verificationCode={verificationCode} />;
  } else {
    return <Navigate to="/" />;
  }
};

export default EmailVerification;

