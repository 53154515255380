import React from 'react';
import NavBarThree from '../navbar/NavBarThree';
import { useNavigate } from 'react-router-dom';
import Footer from '../footer/Footer';

const job = {
    id: '05644',
    location: 'Nairobi',
    type: 'Saloon Car',
    make: 'Toyota',
    photo: '/assets/car5.png',
    requirements:'5 years driving exaperince with saloon cars',
};

function JobPostingPayment() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/welcomepostjob');
    };


    return (
        <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: 'url(/assets/background3.svg)' }}>
            <NavBarThree />

            <div className=" flex-grow relative flex justify-center">
                <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg w-full max-w-full h-auto overflow-y-auto mx-4 md:mx-8">
                    {/* vehicles details */}
                    <div className='md:flex justify-start items-start w-full'>
                        <div className='md:w-2/5 px-4 pt-4 pb-8'>
                            <h1 className='font-serif text-xl text-black  xl:text-2xl text-left font-bold px-4 py-2'>New Job</h1>
                            <div className="grid grid-cols-1 gap-4">
                                <div key={job.id} className="bg-gray-300 w-full py-4 px-1 md:px-6 flex flex-col items-start rounded-xl gap-1">
                                    <p className="text-xs xl:text-lg font-serif mr-2">{`Job ID: ${job.id}`}</p>
                                    <p className="text-xs xl:text-lg font-serif mr-2">Location: {job.location}</p>
                                    <p className="text-xs xl:text-lg font-serif mr-2">Type: {job.type}</p>
                                    <p className="text-xs xl:text-lg font-serif mr-2">Make: {job.make}</p>
                                    <div className='flex flex-col w-full items-start'>
                                        <p className="text-xs xl:text-lg font-serif mr-0">Photo:</p>
                                        <div className='p-2 w-3/5 bg-orange-600 rounded-lg'>
                                            <img src={job.photo} alt="Car" className="w-full h-auto" />
                                        </div>
                                        <p className="text-xs xl:text-lg font-serif mr-2 text-left">Requirements: {job.requirements}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        {/* payment details */}
                        <div className='md:w-3/5 px-4 pt-4 pb-8'>
                            <h1 className='font-serif xl:text-2xl text-xl text-black text-left font-bold px-4 py-2'>Make Payment</h1>
                            <div className="grid p-2 grid-cols-1 gap-4">
                                <div className="bg-gray-300 w-full py-4 px-1 md:px-6 flex flex-col items-start rounded-xl gap-1">
                                    <div className="flex items-center space-x-2">
                                        <input type="checkbox" id="salaryNegotiable" name="salaryNegotiable" className="h-4 w-4 text-orange-700 border-gray-300 rounded focus:ring-orange-600" required />
                                        <label htmlFor="salaryNegotiable" className="font-serif">
                                            <span className="text-black-600 mb-1  text-black text-xs xl:text-xl font-serif">
                                                Fetch Uploaded Profile Information
                                            </span>
                                        </label>
                                    </div>
                                    <p className="text-orange-600  xl:text-lg text-xs font-serif py-2 underline">Click to update profile</p>
                                    <div className='py-1 w-full'>
                                        <h1 className='font-serif font-bold  xl:text-2xl text-lg text-left'>Make Payment</h1>
                                        <p className='font-serif xl:text-lg text-xs text-left py-1'>
                                            You are posting <span className='font-semibold'>Job ID: 0254772</span> from Dere.com on June 30th, 2024. Once the application is
                                            <b /> complete, we will add your job details and send all the application to your email.
                                        </p>
                                        <p className='font-serif xl:text-lg text-xs text-left py-1'>
                                            The price for our service is <span className='font-semibold'>KSH 100</span>. You will be required to pay this amount for us to process your
                                            application.
                                        </p>
                                        <h2 className='font-serif font-bold  xl:text-lg text-sm text-left'>PAY KES 100.00</h2>
                                        <div className='text-xs py-1 font-serif ml-4'>
                                            <p className='text-left xl:text-sm '>1. Provide your MPESA mobile number below</p>
                                            <p className='text-left xl:text-sm'>2. Click Proceed and a Prompt will appear on your phone requesting you to confirm the transaction by providing your MPESA PIN</p>
                                            <p className='text-left xl:text-sm'>3. Once completed, you will receive the confirmation SMS for this transaction</p>
                                        </div>

                                        <div className='flex mt-2'>
                                            <div className='md:w-[75%] w-[65%] py-2 bg-gray-400 rounded-lg'>
                                                <div className="flex items-start">
                                                    <label className="md:ml-7 block text-gray-700 xl:text-lg px-2 text-xs md:text-sm mt-1 font-serif" htmlFor="lastName">+254</label>
                                                    <input className="appearance-none text-xs  xl:text-lg md:ml-6 block w-3/4  placeholder-black bg-gray-500 text-gray-700 font-serif  border rounded-lg py-1 xl:py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="lastName" type="text" placeholder="e.g 7xx xxx xxx" />
                                                </div>
                                            </div>
                                            <div className='w-[25%]'>
                                                <button className="text-white font-serif font-semibold text-sm  xl:text-xl rounded-xl bg-orange-700 mt-1 py-1.5 px-4 md:px-6 mx-2  md:mx-8" onClick={handleClick} >
                                                    Proceed
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
            <Footer />
        </div>
    );
}

export default JobPostingPayment;


