import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import NavBarTwo from "../navbar/NavBarTwo";
import Footer from "../footer/Footer";

function PartnerFormTwo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData: initialData } = location.state || {};

  const openTermsAndConditions = (e) => {
    e.preventDefault(); 
    window.open("/terms-and-conditions", "_blank", "noopener,noreferrer");
  };

  const openPrivacyPolicy = (e) => {
    e.preventDefault();
    window.open("/privacy-policy", "_blank", "noopener,noreferrer");
  };

  const [formData, setFormData] = useState({
    ...initialData,
    vehicle_type: "",
    preferred_work_area: "",
    id_photo: "",
    car_photo: "",
  });

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vehicleResponse = await axios.get("https://derebackend-1.onrender.com/api/vehicle-types/");
        // const vehicleResponse = await axios.get('http://127.0.0.1:8000/api/vehicle-types/');
        setVehicleTypes(vehicleResponse.data);

        // const workAreasResponse = await axios.get('http://127.0.0.1:8000/api/work-areas/');
        const workAreasResponse = await axios.get("https://derebackend-1.onrender.com/api/work-areas/");
        setWorkAreas(workAreasResponse.data);
      } catch (error) {
        console.error("Error fetching vehicle models or work areas:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "vehicle_type" || name === "work_area") {
      setFormData({ ...formData, [name]: parseInt(value, 10) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg"];
      if (validTypes.includes(file.type)) {
        setFormData({ ...formData, [name]: file });
      } else {
        alert("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    // Validation: Check if all required fields are filled
    if (
      !formData.vehicle_type ||
      !formData.preferred_work_area ||
      !formData.id_photo ||
      !formData.car_photo
    ) {
      let errors = [];
      if (!formData.vehicle_type) errors.push("Please select a vehicle type.");
      if (!formData.preferred_work_area)
        errors.push("Please select preferred work area.");
      if (!formData.id_photo) errors.push("Please upload your ID photo.");
      if (!formData.car_photo) errors.push("Please upload your car photo.");

      setErrorMessage(errors.join(" "));
      setIsSubmitting(false);
      return;
    }

    // Create FormData object to handle file uploads
    const submissionData = new FormData();
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== "") {
        submissionData.append(key, formData[key]);
      }
    }

    console.log("Form data before submission:", formData);

    for (let pair of submissionData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      // const response = await axios.post('http://127.0.0.1:8000/api/partners/', submissionData, {
      const response = await axios.post("https://derebackend-1.onrender.com/api/partners/", submissionData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
      );

      if (response.status === 201) {
        navigate("/welcomepartner", {
          state: {
            successPopup: "Account created successfully!",
            formData: {
              first_name: formData.first_name,
              email: formData.email,
            },
          },
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.non_field_errors) {
            setErrorMessage(error.response.data.non_field_errors.join(" "));
          } else if (
            error.response.data &&
            typeof error.response.data === "object"
          ) {
            const errorMessages = Object.values(error.response.data)
              .flat()
              .join(" ");
            setErrorMessage(errorMessages);
          } else {
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      }
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen bg-cover w-full"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      <NavBarTwo />
      <div className="flex flex-col flex-grow items-center mt-8 mb-8">
        <div className="flex-grow w-full flex justify-center items-start px-8">
          <div
            className="md:flex bg-white bg-opacity-50 rounded-xl shadow-lg w-full"
            style={{ height: "95%" }}
          >
            <div className="bg-gray-200 p-5 md:w-1/3 flex flex-col justify-center md:rounded-xl md:shadow-lg">
              <div className="text-center">
                <h2 className="md:text-2xl text-xl xl:text-4xl font-bold font-serif text-black">
                  Step Two
                </h2>
                <h2 className="md:text-2xl text-xl xl:text-3xl font-bold font-serif text-black">
                  Partner Information
                </h2>
                <p className="text-black mt-2 text-center font-serif text-sm md:text-lg xl:text-2xl">
                  This is information pertaining to your
                  <br /> company in general and your car <br /> leasing
                  preferences
                </p>
              </div>
            </div>
            <div className="bg-white bg-opacity-50 p-5 md:w-2/3 flex flex-col md:rounded-r-xl">
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                {errorMessage && (
                  <div className="flex justify-center items-center">
                    <div className="error-alert bg-red-500 text-white p-2 text-xs font-serif md:w-1/2">
                      {errorMessage}
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 gap-4 mt-4 md:mr-32">
                  <div className="flex flex-col md:mr-32 xl:mt-8">
                    <label
                      htmlFor="vehicle_type"
                      className="mb-1 text-black uppercase xl:text-lg text-xs md:pl-16  md:text-left font-serif"
                    >
                      Which Vehicle Type Are You Looking to Lease?
                      <span className="px-1 text-red-700">*</span>
                    </label>
                    <select
                      id="vehicle_type"
                      name="vehicle_type"
                      className="p-3 text-sm md:text-xs xl:text-lg font-serif border border-gray-300 rounded-lg md:ml-16  w-[90%] md:w-3/4 mx-auto"
                      value={formData.vehicle_type}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      {vehicleTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col md:mr-32">
                    <label
                      htmlFor="preferred_work_area"
                      className="mb-1 text-black uppercase md:pl-16  text-xs md:text-left  xl:text-lg font-serif"
                    >
                      Preferred Leasing Work Area
                      <span className="px-1 text-red-700">*</span>
                    </label>
                    <select
                      id="preferred_work_area"
                      name="preferred_work_area"
                      className="p-3 text-sm md:text-xs border border-gray-300 xl:text-lg md:w-3/4 md:ml-16 font-serif rounded-lg w-[90%]  mx-auto"
                      value={formData.preferred_work_area}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      {workAreas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 ">
                  <div className="flex flex-col mb-4 items-start">
                    <label
                      htmlFor="id_photo"
                      className="md:ml-14 ml-4 mb-1 text-black uppercase text-xs xl:text-lg font-serif"
                    >
                      ID Photo<span className="px-1 text-red-700">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="id_photo"
                        className="md:ml-14 ml-4 bg-gray-500 shadow-md border border-gray-500 px-6 py-3 text-white text-xs md:text-xs xl:text-sm font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="id_photo"
                          name="id_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </label>
                      <span className="ml-2 text-black text-xs md:text-xs">
                        {formData.id_photo
                          ? formData.id_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col md:mr-16 items-start">
                    <label
                      htmlFor="car_photo"
                      className="ml-4 mb-1 text-black uppercase text-xs xl:text-lg font-serif"
                    >
                      Car Photo<span className="px-1 text-red-700">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="car_photo"
                        className="ml-4 bg-gray-500 shadow-md border border-gray-500 px-6 py-3 text-white text-xs xl:text-sm md:text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="car_photo"
                          name="car_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </label>
                      <span className="ml-2 text-black text-xs md:text-xs">
                        {formData.car_photo
                          ? formData.car_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex items-center space-x-2 mt-8 md:ml-14 md:px-5">
                    <input
                      type="checkbox"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      className="h-4 w-4 text-orange-700 border-gray-300 rounded focus:ring-orange-600"
                      required
                    />
                    <label
                      htmlFor="termsAndConditions"
                      className="font-serif text-left text-xs xl:text-lg uppercase"
                    >
                      I AGREE TO THE
                      <a
                        href="/terms-and-conditions"
                        className="text-orange-600 hover:underline ml-1 mr-1"
                        onClick={openTermsAndConditions}
                      >
                        TERMS AND CONDITIONS
                      </a>
                      AND
                      <a
                        href="/privacy-policy"
                        className="text-orange-600 hover:underline ml-1"
                        onClick={openPrivacyPolicy}
                      >
                        PRIVACY POLICY
                      </a>
                    </label>
                  </div>

                  <div className="flex justify-end xl:mt-8 xl:mb-8 mt-4 mb-4">
                    <button
                      type="submit"
                      className="bg-orange-700 text-white py-2 px-4 rounded-md hover:bg-orange-600 text-xs xl:text-lg font-serif"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Creating Account ..." : "Create Account"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PartnerFormTwo;
